import createReducer, { RESET_STORE } from "../createReducer";
import { getToken } from "./user";
import qs from "query-string";
import _ from "lodash";
import { message } from "antd";
import { SHORT_MESSAGE_DELAY, LONG_MESSAGE_DELAY, VOLUME_UNITS, MASS_UNITS } from "../constants";
import messages from "../messages";
import { changeSelectedRawMaterials, reorderSelectedRawMaterials, getRawMaterials } from "./rawMaterials";
import { getTotalPrice } from "./product";
import { uploadHelper } from "./uploadHelper";
import { updateDilutionPercent } from "./dilution";

import axios from "../config-axios";

const convert = require("convert-units");

// ------------------------------------
// Constants
// ------------------------------------
export const GET_RECIPES_REQUEST = "Recipe.GET_RECIPES_REQUEST";
export const GET_RECIPES_SUCCESS = "Recipe.GET_RECIPES_SUCCESS";
export const GET_RECIPES_FAILURE = "Recipe.GET_RECIPES_FAILURE";

export const GET_PRICES_REQUEST = "Recipe.GET_PRICES_REQUEST";
export const GET_PRICES_SUCCESS = "Recipe.GET_PRICES_SUCCESS";
export const GET_PRICES_FAILURE = "Recipe.GET_PRICES_FAILURE";

export const ADD_RECIPE_REQUEST = "Recipe.ADD_RECIPE_REQUEST";
export const ADD_RECIPE_SUCCESS = "Recipe.ADD_RECIPE_SUCCESS";
export const ADD_RECIPE_FAILURE = "Recipe.ADD_RECIPE_FAILURE";

export const UPDATE_RECIPE_REQUEST = "Recipe.UPDATE_RECIPE_REQUEST";
export const UPDATE_RECIPE_SUCCESS = "Recipe.UPDATE_RECIPE_SUCCESS";
export const UPDATE_RECIPE_FAILURE = "Recipe.UPDATE_RECIPE_FAILURE";

export const OPEN_RECIPES_MODAL = "Recipe.OPEN_RECIPES_MODAL";
export const CLOSE_RECIPES_MODAL = "Recipe.CLOSE_RECIPES_MODAL";

export const CHANGE_RECIPE_SEARCH_MODAL = "Recipe.CHANGE_RECIPE_SEARCH_MODAL";

export const CHANGE_SELECTED_RECIPE = "Recipe.CHANGE_SELECTED_RECIPE";

export const CLEAR = "Recipe.CLEAR";

export const CLEAR_SELECTED_RECIPES = "Recipe.CLEAR_SELECTED_RECIPES";

export const UPDATE_SELECTED_RECIPE = "Recipe.UPDATE_SELECTED_RECIPE";

export const DELETE_PRICE = "Recipe.DELETE_PRICE";

export const UPLOAD_ACTION = "Recipe.UPLOAD_ACTION";
export const UPLOAD_FAILURE = "Recipe.UPLOAD_FAILURE";
export const SET_TOTAL_GRAM = "Recipe.SET_TOTAL_GRAM";
export const SET_COST_PER_LITER = "Recipe.SET_COST_PER_LITER";

// ------------------------------------
// Actions
// ------------------------------------
export const getRecipes =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: GET_RECIPES_REQUEST, params });
    const { token } = dispatch(getToken());
    const { search, ordering } = getState().recipe;
    return fetch(
      `/pands/recipes/?${qs.stringify({
        search,
        ordering,
      })}`,
      {
        method: "GET",
        token,
        success: (res) => dispatch({ type: GET_RECIPES_SUCCESS, res }),
        // eslint-disable-next-line node/handle-callback-err
        failure: (err) => dispatch({ type: GET_RECIPES_FAILURE }),
      }
    );
  };

export const convertGramsToUnit = (raw_material, quantity, unit, user) => {
  let dropsperml = user && user.preferences ? user.preferences.dropsperml : 30.0;
  dropsperml = isNaN(dropsperml) ? 30.0 : parseFloat(dropsperml);
  if (unit === "floz") {
    unit = "fl-oz";
  }
  let result = null;
  if (VOLUME_UNITS.find((element) => element === unit) || unit === "fl-oz") {
    // convert to ml first , g = ml/density
    const ml = quantity / parseFloat(raw_material.raw_ingredient.density);
    if (unit != "ml") {
      result = convert(ml).from("ml").to(`${unit}`);
    } else {
      return ml;
    }
  } else if (unit === "drops") {
    result = quantity * dropsperml;
  } else if (MASS_UNITS.find((element) => element === unit)) {
    result = convert(quantity).from("g").to(`${unit}`);
  } else {
    result = convert(quantity).from("g").to(`${unit}`);
  }
  return parseFloat(result);
};

export const convertUnit = (raw_material, quantity, unit, user) => {
  let dropsperml = user && user.preferences ? user.preferences.dropsperml : 30.0;
  dropsperml = isNaN(dropsperml) ? 30.0 : parseFloat(dropsperml);
  if (unit === "floz") {
    unit = "fl-oz";
  }
  let valueGram = null;
  if (VOLUME_UNITS.find((element) => element === unit) || unit === "fl-oz") {
    const ml = convert(quantity).from(`${unit}`).to("ml");
    valueGram = ml * parseFloat(raw_material.raw_ingredient.density);
  } else if (unit === "drops") {
    valueGram = quantity / dropsperml;
  } else if (MASS_UNITS.find((element) => element === unit)) {
    valueGram = convert(quantity).from(`${unit}`).to("g");
  } else {
    valueGram = convert(quantity).from(`${unit}`).to("g");
  }
  return parseFloat(valueGram);
};

const setPrices = (values, selectedRawMaterials, currencies, currency, user) => {
  const prices = [];
  const dilutionArray = [];

  // values are form field values that are getting compared
  values.ingredients.forEach((ingredient) => {
    if (ingredient && ingredient.quantity && ingredient.unit) {
      const priceArray = {};
      selectedRawMaterials.forEach((selectedRawMaterial) => {
        if (ingredient.raw_material === selectedRawMaterial.raw_material.id) {
          priceArray.raw_material = selectedRawMaterial.raw_material.id;
          const currencyArray = {};
          const valueGram = convertUnit(selectedRawMaterial.raw_material, ingredient.quantity, ingredient.unit, user);
          const USD = valueGram * selectedRawMaterial.raw_material.ppu;

          currencies.forEach((cur) => {
            currencyArray[cur.key] = cur.rate * USD;
          });
          priceArray.currencies = currencyArray;
          dilutionArray.push({
            id: selectedRawMaterial.raw_material.id,
            weight: parseFloat(valueGram),
          });
        }
      });
      prices.push(priceArray);
    }
  });

  return [prices, dilutionArray];
};

const setCosmeticPrices = (values, selectedRawMaterials, currencies, currency, user, cosmeticTotalRecipeQuantity) => {
  const prices = [];
  const dilutionArray = [];
  //    const rtotal = values.recipe_total;
  const rtotal = cosmeticTotalRecipeQuantity;
  const runits = values.recipe_totalunit;

  values.ingredients.forEach((ingredient) => {
    const priceArray = {};
    selectedRawMaterials.forEach((selectedRawMaterial) => {
      if (ingredient.raw_material === selectedRawMaterial.raw_material.id) {
        priceArray.raw_material = selectedRawMaterial.raw_material.id;
        const currencyArray = {};
        const thisqty = (rtotal * (ingredient.quantity ? ingredient.quantity : selectedRawMaterial.quantity)) / 100.0;
        const thisunit = runits;
        const valueGram = convertUnit(selectedRawMaterial.raw_material, thisqty, thisunit, user);
        const USD = valueGram * selectedRawMaterial.raw_material.ppu;

        currencies.forEach((cur) => {
          currencyArray[cur.key] = cur.rate * USD;
        });
        priceArray.currencies = currencyArray;
        dilutionArray.push({
          id: selectedRawMaterial.raw_material.id,
          weight: parseFloat(valueGram),
        });
      }
    });
    prices.push(priceArray);
  });
  return [prices, dilutionArray];
};

export const getPrices =
  (values) =>
  (dispatch, getState, { fetch }) => {
    const { user } = getState().user;
    dispatch({ type: GET_PRICES_REQUEST });
    const { currencies, currency } = getState().global;
    const { selectedRawMaterials, cosmeticTotalRecipeQuantity } = getState().rawMaterials;
    const { cosmetic } = getState().rawMaterials;
    let totalGrams = 0;
    if (!values.ingredients) {
      return;
    }
    let results = {};

    if (cosmetic) {
      results = setCosmeticPrices(values, selectedRawMaterials, currencies, currency, user, cosmeticTotalRecipeQuantity);
    } else {
      results = setPrices(values, selectedRawMaterials, currencies, currency, user);
    }

    const prices = results[0];
    const dilutionArray = results[1];
    dilutionArray.map((ingredient, index) => {
      totalGrams = totalGrams + ingredient.weight;
    });

    totalGrams = parseFloat(totalGrams);
    dispatch({ type: SET_TOTAL_GRAM, totalGrams });
    dispatch(updateDilutionPercent(dilutionArray));
    dispatch({ type: GET_PRICES_SUCCESS, prices });
    dispatch(getTotalPrice());
    if (cosmetic) {
      dispatch(setPricePerLiter(selectedRawMaterials));
    }
  };

export const changeRecipeSearch = () => ({ type: CHANGE_RECIPE_SEARCH_MODAL });

export const openRecipesModal = () => (dispatch, getState) => {
  dispatch({ type: OPEN_RECIPES_MODAL });
  dispatch(getRecipes());
};

export const closeRecipesModal = () => ({ type: CLOSE_RECIPES_MODAL });

const convertToCosmetic = (selectedRecipe) => {
  const modifiedRecipe = { ...selectedRecipe };
  let unit = modifiedRecipe.totalunit;
  modifiedRecipe.ingredients.forEach((i) => {
    if (unit === "floz") {
      unit = "fl-oz";
    }
    let newquantity;
    if (VOLUME_UNITS.find((element) => element === unit) || unit === "fl-oz") {
      const ml = parseFloat(i.quantity / i.raw_material.raw_ingredient.density);
      newquantity = convert(ml).from("ml").to(`${unit}`);
    } else {
      newquantity = i.quantity;
    }

    i.quantity = parseFloat((newquantity / modifiedRecipe.total) * 100.0);
  });
};

export const changeSelectedRecipe = (selectedRecipe) => (dispatch, getState) => {
  const { cosmetic } = getState().recipe;
  if (cosmetic) {
    convertToCosmetic(selectedRecipe);
  }
  dispatch({ type: CHANGE_SELECTED_RECIPE, selectedRecipe });
  dispatch(changeSelectedRawMaterials(selectedRecipe ? selectedRecipe.ingredients : []));
};

export const setPricePerLiter = (selectedRawMaterials) => (dispatch, getState) => {
  const { user } = getState().user;
  const { cosmeticTotalRecipeQuantity } = getState().rawMaterials;
  //    const {cosmeticRecipeUnit} = getState().rawMaterial
  const cosmeticRecipeUnit = "g";
  let costPerLiter = 0;
  selectedRawMaterials.forEach((selectedRawMaterial) => {
    const grams = convertUnit(selectedRawMaterial.raw_material, selectedRawMaterial.quantity, cosmeticRecipeUnit, user);
    const density = parseFloat(selectedRawMaterial.raw_material.raw_ingredient.density);
    const liters = parseFloat(parseFloat(grams) / density / 1000);
    costPerLiter += liters;
  });
  dispatch({ type: SET_COST_PER_LITER, costPerLiter });
};

export const dispatchNewRecipeRequest =
  () =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: ADD_RECIPE_REQUEST });
  };

export const dispatchNewRecipeSuccess =
  (recipe) =>
  (dispatch, getState, { fetch }) => {
    const { recipes } = getState().recipe;
    dispatch({ type: ADD_RECIPE_SUCCESS, recipes: [...recipes, recipe] });
    dispatch(changeSelectedRecipe(recipe));
  };

// eslint-disable-next-line node/handle-callback-err
export const dispatchNewRecipeFailure =
  (error) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: ADD_RECIPE_FAILURE });
  };

export const updateRecipeState = (selectedRawMaterials, values) => {
  for (let ingredient = 0; ingredient < selectedRawMaterials.length; ingredient++) {
    for (let value = 0; value < values.ingredients.length; value++) {
      if (values.ingredients[value] && selectedRawMaterials[ingredient].raw_material.id === values.ingredients[value].raw_material) {
        selectedRawMaterials[ingredient].quantity = values.ingredients[value].quantity;
        selectedRawMaterials[ingredient].unit = values.ingredients[value].unit;
      }
    }
  }
};

export const updateRecipeIngredientsState =
  (selectedRecipe) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: UPDATE_SELECTED_RECIPE, selectedRecipe });
  };

export const onRecipeImportChange = (event) => (dispatch, getState) => {
  let { selectedFileList, uid } = getState().recipe;
  const { token } = dispatch(getToken());
  const feedback = uploadHelper(event, uid, selectedFileList);
  selectedFileList = feedback[0];
  uid = feedback[1];
  dispatch({ type: UPLOAD_ACTION, selectedFileList, uid });
  if (feedback.length === 3) {
    const { apiUrl } = getState().global;
    const formData = new FormData();
    formData.append("file", event.file.originFileObj, event.file.originFileObj.name);
    return fetch(apiUrl + "/pands/uploadrm/", {
      method: "POST",
      // token,
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: UPLOAD_FAILURE });
        message.error(messages.uploadFailure, LONG_MESSAGE_DELAY);
      },
    })
      .then((response) => response.json())
      .then((parsedResponse) => {
        if (parsedResponse.status === false) {
          message.error(parsedResponse.details, LONG_MESSAGE_DELAY);
        } else {
          message.success("Ingredients uploaded successfully.", SHORT_MESSAGE_DELAY);
        }
        dispatch(getRawMaterials());
      });
  }
};

export const dispatchUpdateRecipeRequest =
  () =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: UPDATE_RECIPE_REQUEST });
  };

export const dispatchUpdateSelectedRecipe =
  (selectedRecipe) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: UPDATE_SELECTED_RECIPE, selectedRecipe });
  };

export const dispatchUpdateRecipeSuccess =
  (response) =>
  (dispatch, getState, { fetch }) => {
    const { recipes } = getState().recipe;
    dispatch({
      type: UPDATE_RECIPE_SUCCESS,
      recipes: recipes.map((item) => (item.id === response.data.recipe.id ? response.data.recipe : item)),
    });
  };

export const dispatchUpdateRecipeFailure =
  (error) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: UPDATE_RECIPE_FAILURE });
    return Promise.reject(error);
  };

export const updateRecipe =
  (values) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: UPDATE_RECIPE_REQUEST });
    const { selectedRawMaterials } = getState().rawMaterials;
    const { token } = dispatch(getToken());
    const { selectedRecipe, recipes } = getState().recipe;
    const { apiUrl } = getState().global;
    updateRecipeState(selectedRawMaterials, values);
    selectedRecipe.ingredients = selectedRawMaterials;
    dispatch({ type: UPDATE_SELECTED_RECIPE, selectedRecipe });

    return axios({
      method: "patch",
      url: apiUrl + `/pands/recipes/${selectedRecipe.id}/`,
      data: {
        ingredients: selectedRawMaterials.map((item) => _.compact(values.ingredients).find((v) => v.raw_material === item.raw_material.id)),
        action: "replace",
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        dispatch({
          type: UPDATE_RECIPE_SUCCESS,
          recipes: recipes.map((item) => (item.id === response.id ? response : item)),
        });
        message.success(messages.updateRecipeSuccess, SHORT_MESSAGE_DELAY);
        return response;
      })
      .catch((error) => {
        dispatch({ type: UPDATE_RECIPE_FAILURE });
        message.error(messages.updateRecipeError, SHORT_MESSAGE_DELAY);
        return Promise.reject(error);
      });
  };

export const deleteRowWithPrices = (id) => (dispatch, getState) => {
  let { prices } = getState().recipe;
  prices = prices.filter((property) => {
    if (property.raw_material === id) {
      return false;
    }
    return true;
  });
  dispatch({ type: DELETE_PRICE, prices });
};

export const clearOnlySelectedRecipes = () => (dispatch) => {
  dispatch({ type: CLEAR_SELECTED_RECIPES });
};

export const clear = () => {
  return { type: CLEAR };
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: {
    recipes: false,
    addingRecipe: false,
    updatingRecipe: false,
    prices: false,
  },
  recipes: [],
  prices: [],
  search: undefined,
  ordering: undefined,
  filters: {},
  recipeSearch: undefined,
  recipesModalOpened: false,
  selectedRecipe: undefined,
  error: null,
  selectedFileList: null,
  uid: 0,
  totalGrams: 0,
  total: 0,
  totalunit: "",
  costPerLiter: 0,
};

export default createReducer(initialState, {
  [GET_RECIPES_REQUEST]: (state, { params }) => ({
    search: params.search !== undefined ? params.search : state.search,
    ordering: params.sorter ? `${params.sorter.order === "descend" ? "-" : ""}${params.sorter.field}` : state.ordering,
    filters: params.filters || state.filters,
    loading: {
      ...state.loading,
      recipes: true,
    },
  }),
  [GET_RECIPES_SUCCESS]: (state, { res: { results } }) => ({
    recipes: results,
    loading: {
      ...state.loading,
      recipes: false,
    },
  }),
  [GET_RECIPES_FAILURE]: (state, action) => ({
    loading: {
      ...state.loading,
      recipes: false,
    },
  }),
  [OPEN_RECIPES_MODAL]: (state, action) => ({
    recipesModalOpened: true,
  }),
  [CLOSE_RECIPES_MODAL]: (state, action) => ({
    recipesModalOpened: false,
    search: undefined,
  }),
  [CHANGE_RECIPE_SEARCH_MODAL]: (state, { recipeSearch }) => ({
    recipeSearch,
  }),
  [CHANGE_SELECTED_RECIPE]: (state, { selectedRecipe }) => ({
    selectedRecipe,
  }),
  [ADD_RECIPE_REQUEST]: (state, action) => ({
    loading: {
      ...state.loading,
      addingRecipe: true,
    },
  }),
  [ADD_RECIPE_SUCCESS]: (state, { recipes }) => ({
    recipes,
    loading: {
      ...state.loading,
      addingRecipe: false,
    },
  }),
  [ADD_RECIPE_FAILURE]: (state, action) => ({
    loading: {
      ...state.loading,
      addingRecipe: false,
    },
  }),
  [UPDATE_RECIPE_REQUEST]: (state, action) => ({
    loading: {
      ...state.loading,
      updatingRecipe: true,
    },
  }),
  [UPDATE_RECIPE_SUCCESS]: (state, { recipes }) => ({
    recipes,
    loading: {
      ...state.loading,
      updatingRecipe: false,
    },
  }),
  [UPDATE_RECIPE_FAILURE]: (state, action) => ({
    loading: {
      ...state.loading,
      updatingRecipe: false,
    },
  }),
  [GET_PRICES_REQUEST]: (state, action) => ({
    prices: [],

    error: null,
    loading: {
      ...state.loading,
      prices: true,
    },
  }),
  [GET_PRICES_SUCCESS]: (state, { prices }) => ({
    prices,
    loading: {
      ...state.loading,
      prices: false,
    },
  }),
  [GET_PRICES_FAILURE]: (state, { error }) => ({
    error,
    loading: {
      ...state.loading,
      prices: false,
    },
  }),
  [UPDATE_SELECTED_RECIPE]: (state, { selectedRecipe }) => ({
    selectedRecipe,
  }),
  [DELETE_PRICE]: (state, { prices }) => ({
    prices,
  }),
  [UPLOAD_ACTION]: (state, { /* selectedFile, */ selectedFileList, uid }) => ({
    // selectedFile,
    selectedFileList,
    uid,
  }),
  [UPLOAD_FAILURE]: (state, { error }) => ({
    error,
  }),
  [CLEAR_SELECTED_RECIPES]: (state, action) => ({
    prices: [],
    selectedRecipe: undefined,
    costPerLiter: 0,
  }),
  [CLEAR]: (state, action) => RESET_STORE,
  [SET_TOTAL_GRAM]: (state, { totalGrams, totalUnits }) => ({
    totalGrams,
    recipe_totalunit: totalUnits,
  }),
  [SET_COST_PER_LITER]: (state, { costPerLiter }) => ({
    costPerLiter,
  }),
});
