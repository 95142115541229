import createReducer from "../createReducer";

export const GET_DETAILS = "piechart.GET_DETAILS";

const initialState = {
  options: [],
};

export default createReducer(initialState, {
  [GET_DETAILS]: (state, action) => ({
    options: action.options,
  }),
});
