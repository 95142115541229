import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "resetPassword.title",
    defaultMessage: "Reset Password",
  },
  header: {
    id: "resetPassword.header",
    defaultMessage: "Reset Password",
  },
  email: {
    id: "resetPassword.email",
    defaultMessage: "Email",
  },
  submit: {
    id: "resetPassword.submit",
    defaultMessage: "Submit",
  },
  success: {
    id: "resetPassword.success",
    defaultMessage: "Please go to your email and follow the instructions to reset your password.",
  },
  error: {
    id: "resetPassword.error",
    defaultMessage: "Please make sure you have the right email address and that you have already activated your account and try again.",
  },
});
