export default {
  // Notifications
  activateSuccess: "User was successfully activated",
  activateError: "Activation unsuccessful",
  loginError: "Something went wrong", // in a case if no backend error
  addRecipeSuccess: "Blend saved",
  addRecipeError: "Saving blend failed",
  updateRecipeSuccess: "Blend updated",
  updateRecipeError: "Updating blend failed",
  addContainerSuccess: "Container saved",
  addContainerError: "Saving container failed",
  updateContainerSuccess: "Container updated",
  updateContainerError: "Updating container failed",
  addProductSuccess: "Recipe saved",
  addProductError: "Saving recipe failed",
  updateProductSuccess: "Recipe updated",
  updateProductError: "Updating recipe failed",
  // Forms
  required: "This field is required",
  invalidEmail: "This is not a valid email address",
};
