import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "setPassword.title",
    defaultMessage: "Set Password",
  },
  header: {
    id: "setPassword.header",
    defaultMessage: "Set Password",
  },
  password: {
    id: "setPassword.password",
    defaultMessage: "Please type new password",
  },
  confirmPassword: {
    id: "setPassword.confirmPassword",
    defaultMessage: "Confirm password",
  },
  submit: {
    id: "setPassword.submit",
    defaultMessage: "Submit",
  },
  success: {
    id: "setPassword.success",
    defaultMessage: "Password is successfully changed.",
  },
  error: {
    id: "setPassword.error",
    defaultMessage: "Something went wrong. Please try again.",
  },
});
