import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "login.title",
    defaultMessage: "Blend Precisely",
  },
  header: {
    id: "login.header",
    defaultMessage: "Blend Precisely & Indie AI Business Hub",
  },
  email: {
    id: "login.email",
    defaultMessage: "Email",
  },
  password: {
    id: "login.password",
    defaultMessage: "Password",
  },
  submit: {
    id: "login.submit",
    defaultMessage: "Sign in",
  },
  forgotPassword: {
    id: "login.forgotPassword",
    defaultMessage: "Forgot password?",
  },
  notRegistered: {
    id: "login.notRegistered",
    defaultMessage: "Not registered yet?",
  },
  loginError: {
    id: "login.loginError",
    defaultMessage: "Email or password is incorrect.",
  },
  activationSuccess: {
    id: "login.activationSuccess",
    defaultMessage: "User is successfully activated.",
  },
  activationError: {
    id: "login.activationError",
    defaultMessage: "Activation failed. Please try again.",
  },
});
