import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "register.title",
    defaultMessage: "Register",
  },
  header: {
    id: "register.header",
    defaultMessage: "Register",
  },
  email: {
    id: "register.email",
    defaultMessage: "Email",
  },
  country: {
    id: "register.country",
    defaultMessage: "Country",
  },
  firstName: {
    id: "register.firstName",
    defaultMessage: "First name",
  },
  lastName: {
    id: "register.lastName",
    defaultMessage: "Last name",
  },
  password: {
    id: "register.password",
    defaultMessage: "Password",
  },
  confirmPassword: {
    id: "register.confirmPassword",
    defaultMessage: "Confirm password",
  },
  submit: {
    id: "register.submit",
    defaultMessage: "Submit",
  },
  success: {
    id: "register.success",
    defaultMessage: "Your account has been successfully registered. Please go to your email to finish your registration.",
  },
  error: {
    id: "register.error",
    defaultMessage: "Something went wrong. Please try again.",
  },
});
