import createReducer, { RESET_STORE } from "../createReducer";
import { MODAL_PAGE_SIZE } from "../constants";
import { getToken, clearSessionCurrency } from "./user";
import qs from "query-string";
import _ from "lodash";
import { message, notification } from "antd";
import { SHORT_MESSAGE_DELAY, LONG_MESSAGE_DELAY, PAGE_SIZE } from "../constants";
import messages from "../messages";
import { convertCurrencyValue, changeCurrency } from "./global";
import { updateCosmeticTotalRecipeQuantity } from "./rawMaterials";
import history from "../history";
import {
  dispatchNewRecipeRequest,
  updateRecipeState,
  dispatchNewRecipeSuccess,
  dispatchNewRecipeFailure,
  dispatchUpdateRecipeRequest,
  dispatchUpdateSelectedRecipe,
  dispatchUpdateRecipeSuccess,
  dispatchUpdateRecipeFailure,
  changeSelectedRecipe,
  clear as clearRecipe,
  clearOnlySelectedRecipes,
} from "./recipe";

import { updateCosmeticMode, clearOnlySelectedRecipes as clearRawMaterials, saveScaledBy } from "./rawMaterials";

import { setHydrousMode, updateSelectedIFRACategory, clearIFRA } from "./ifra";

import {
  dispatchNewContainerRequest,
  addOrUpdateContainerState,
  dispatchNewContainerSuccess,
  dispatchNewContainerFailure,
  dispatchUpdateContainerSuccess,
  dispatchUpdateContainerFailure,
  changeSelectedContainer,
  clearSelectedComponent as clearContainer,
} from "./container";

import { removeFalsy } from "../utils";
import { clearSelectedComponent as clearComponents } from "./components";
import { cleardilution } from "./dilution";
import axios from "../config-axios";

// ------------------------------------
// Constants
// ------------------------------------
export const PRODUCT_NAV_REF = "Product.Product.SET_PRODUCT_NAV_REF";
export const GET_PRODUCT_REQUEST = "Product.GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "Product.GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "Product.GET_PRODUCT_FAILURE";

export const CLEAR_PRODUCTS = "Product.CLEAR_PRODUCTS";

export const PRODUCT_COUNT_SUCCESS = "Product.PRODUCT_COUNT_SUCCESS";
export const UPDATE_IMAGE = "Product.UPDATE_IMAGE";

export const GET_USER_CATEGORIES = "Product.GET_USER_CATEGORIES";

export const GET_HOME_PAGE_DATA = "User.GET_HOME_PAGE_DATA";
export const GET_PRODUCT_DETAIL = "Product.GET_PRODUCT_DETAIL";

export const GET_TAGS_REQUEST = "Product.GET_TAGS_REQUEST";
export const GET_TAGS_SUCCESS = "Product.GET_TAGS_SUCCESS";
export const GET_TAGS_FAILURE = "Product.GET_TAGS_FAILURE";

export const ADD_NEW_TAG_SUCCESS = "Product.ADD_NEW_TAG_SUCCESS";

export const UPDATE_TAGS_REQUEST = "Product.UPDATE_TAGS_REQUEST";
export const UPDATE_TAGS_SUCCESS = "Product.UPDATE_TAGS_SUCCESS";
export const UPDATE_TAGS_FAILURE = "Product.UPDATE_TAGS_FAILURE";

export const ADD_PRODUCT_REQUEST = "Product.ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "Product.ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "Product.ADD_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_REQUEST = "Product.UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "Product.UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "Product.UPDATE_PRODUCT_FAILURE";

export const GET_TOTAL_PRICE_REQUEST = "Product.GET_TOTAL_PRICE_REQUEST";
export const GET_TOTAL_PRICE_SUCCESS = "Product.GET_TOTAL_PRICE_SUCCESS";
export const GET_TOTAL_PRICE_FAILURE = "Product.GET_TOTAL_PRICE_FAILURE";

export const OPEN_PRODUCTS_MODAL = "Product.OPEN_PRODUCTS_MODAL";
export const CLOSE_PRODUCTS_MODAL = "Product.CLOSE_PRODUCTS_MODAL";

export const CHANGE_SELECTED_PRODUCT = "Product.CHANGE_SELECTED_PRODUCT";

export const CHANGE_SELECTED_TAG = "Product.CHANGE_SELECTED_TAG";

export const CHANGE_MARKUP_FACTOR = "Product.CHANGE_MARKUP_FACTOR";

export const GET_DEVELOPMENT_HOURS_SUCCESS = "Product.GET_DEVELOPMENT_HOURS_SUCCESS";

export const GET_HOURLY_RATE_SUCCESS = "Product.GET_HOURLY_RATE_SUCCESS";

export const CHANGE_RETAIL_PRICE = "Product.CHANGE_RETAIL_PRICE";

export const EDITING_RETAIL_PRICE = "Product.EDITING_RETAIL_PRICE";

export const RETAIL_PRICE_UPDATED = "Product.RETAIL_PRICE_UPDATED";

export const CLEAR = "Product.CLEAR";

export const GET_SALES_TAX_SUCCESS = "Product.GET_SALES_TAX_SUCCESS";
export const GET_BATCH_SIZE_SUCCESS = "Product.GET_BATCH_SIZE_SUCCESS";
export const GET_SHIPPING_SUCCESS = "Product.GET_SHIPPING_SUCCESS";

export const UPDATE_PRODUCT_LIST = "Product.UPDATE_PRODUCT_LIST";

export const CLEAR_FORMS = "Product.CLEAR_FORMS";

export const MODIFY_SELECTED_PRODUCT = "Product.MODIFY_SELECTED_PRODUCT";

export const SET_SELECTED_PRODUCT = "Product.SET_SELECTED_PRODUCT";

export const ADD_NEW_PRODUCT = "Product.ADD_NEW_PRODUCT";

export const UPDATE_SELECTED_PRODUCT = "Product.UPDATE_SELECTED_PRODUCT";

export const UPDATE_PRODUCT_ID = "Product.UPDATE_PRODUCT_ID";

export const CHANGE_PRODUCTSLOAD = "Product.CHANGE_PRODUCTSLOAD";

export const CHECK_IF_PRODUCT_EXIST = "Product.CHECK_IF_PRODUCT_EXIST";

export const LOADING_PRODUCT = "Product.LOADING_PRODUCT";

export const LOADING_TAGS = "Product.LOADING_TAGS";

export const UPDATE_NOTES = "Product.UPDATE_NOTES";
export const UPDATE_LABEL = "Product.UPDATE_LABEL";

export const UPDATE_DESCRIPTION = "Product.UPDATE_DESCRIPTION";

export const UPDATE_INSTRUCTIONS = "Product.UPDATE_INSTRUCTIONS";
export const PUSH_PRODUCTS = "Product.PUSH_PRODUCTS";

export const PUSH_TAGS = "Product.PUSH_TAGS";
export const CHECK_IF_TAGS_EXIST = "Product.CHECK_IF_TAGS_EXIST";
export const CHANGE_TAGSLOAD = "Product.CHANGE_TAGSLOAD";
export const TAG_EXIST = "Product.TAG_EXIST";
export const SET_DEFAULT_TAGS = "Product.SET_DEFAULT_TAGS";

export const UPDATE_NEW_RECIPE_NAME = "Product.UPDATE_NEW_RECIPE_NAME";

export const STOCK_BLEND_MODE = "Product.TOGGLE_STOCK_BLEND";
export const SAVE_AS_COSMETIC = "Product.SAVE_AS_COSMETIC";
export const SAVE_AS_COSMODE = "Product.SAVE_AS_COSMODE";

const idRegexTest = new RegExp("^/cosmode|^/product");
const cosmodeRegexTest = new RegExp("^/cosmode");

// ------------------------------------
// Actions
// ------------------------------------

export const setStockBlendMode = (value) => (dispatch, getState) => {
  dispatch({ type: STOCK_BLEND_MODE, value });
};

export const clearProducts = (value) => (dispatch, getState) => {
  dispatch({ type: CLEAR_PRODUCTS });
};

export const saveAsCosmetic = (value) => (dispatch, getState) => {
  dispatch({ type: SAVE_AS_COSMETIC, value });
};

export const saveAsCosmode = (value) => (dispatch, getState) => {
  dispatch({ type: SAVE_AS_COSMODE, value });
};

export const handleNotesChange = (notes) => (dispatch, getState) => {
  dispatch({ type: UPDATE_NOTES, notes });
};

export const handleLabelChange = (label) => (dispatch, getState) => {
  dispatch({ type: UPDATE_LABEL, label });
};

export const addTag =
  (name) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());

    const { apiUrl } = getState().global;
    return axios({
      method: "post",
      url: apiUrl + "/pands/tags/",
      data: { name: name },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        const { recipe, container } = response.data;
        return response;
      })
      .catch((error) => {
        message.error(messages.addTagFailed, SHORT_MESSAGE_DELAY);
      });
  };

export const deleteTags =
  (ids) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    const { apiUrl } = getState().global;
    return axios({
      method: "delete",
      url: apiUrl + "/pands/deletetags/",
      data: { ids: ids },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        const { recipe, container } = response.data;
        return response;
      })
      .catch((error) => {
        message.error(messages.addTagFailed, SHORT_MESSAGE_DELAY);
      });
  };

export const handleDescriptionChange = (e) => (dispatch, getState) => {
  const description = e.target.value;
  dispatch({ type: UPDATE_DESCRIPTION, description });
};

export const handleInstructionsChange = (instructions) => (dispatch, getState) => {
  dispatch({ type: UPDATE_INSTRUCTIONS, instructions });
};

export const updateNewRecipeName = (value) => (dispatch, getState) => {
  const newRecipeName = value;
  dispatch({ type: UPDATE_NEW_RECIPE_NAME, newRecipeName });
};

export const checkNewRecipeName = () => (dispatch, getState) => {
  const { newRecipeName } = getState().product;
  if (!newRecipeName) {
    notification.open({
      message: "Error",
      description: "Please create a new name for this Formulation before adding anything new",
    });
    //    message.error("Please create a new name for this Formulation before adding anything new");
    return;
  }
  return true;
};

export const updateImage = (image) => (dispatch, getState) => {
  dispatch({ type: UPDATE_IMAGE, image });
};

export const selectedProductHasStockBlends = () => (dispatch, getState) => {
  const { selectedProduct } = getState().product;
  if (selectedProduct && selectedProduct.stock_blend_products.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const changeSelectedProduct = (selectedProduct) => (dispatch, getState) => {
  if (!selectedProduct || !selectedProduct.currency) {
    return;
  }
  const salesTax = selectedProduct ? selectedProduct.sales_tax : 0;
  const batchSize = selectedProduct ? selectedProduct.batchSize : 1.0;
  const { currency } = getState().global;
  const { user } = getState().user;

  if (!currency) {
    return;
  }
  const shipping = selectedProduct ? parseFloat(selectedProduct.shipping * parseFloat(currency.rate)) : 0.0;

  const markupFactor = selectedProduct ? selectedProduct.markup_factor : null;
  const stock_blend = selectedProduct ? selectedProduct.stock_blend : false;

  const developmentHours = selectedProduct ? parseFloat(selectedProduct.development_hours) : 0.0;

  let hourlyRate = 0.0;
  if (selectedProduct && selectedProduct.hourly_rate && parseFloat(selectedProduct.hourly_rate) > 0.0) {
    hourlyRate = parseFloat(selectedProduct.hourly_rate);
  } else if (user.preferences && user.preferences.hourly_rate) {
    hourlyRate = parseFloat(user.preferences.hourly_rate);
  }

  // const hourlyRate = selectedProduct ? parseFloat(selectedProduct.hourly_rate) : 0.0;
  const notes = selectedProduct ? selectedProduct.notes : "";
  const label = selectedProduct ? selectedProduct.label : "";
  const image = selectedProduct ? selectedProduct.image : "";
  const description = selectedProduct ? selectedProduct.description : "";
  const instructions = selectedProduct ? selectedProduct.instructions : "";
  const selectedTags = selectedProduct ? selectedProduct.tags : null;
  const scaledBy = 1;

  //    if (selectedProduct.recipe.cosmetic) {
  dispatch(updateCosmeticMode(selectedProduct));
  //    }
  dispatch(clearSessionCurrency());
  dispatch({ type: UPDATE_NOTES, notes });
  dispatch({ type: UPDATE_LABEL, label });
  dispatch({ type: UPDATE_IMAGE, image });
  dispatch({ type: UPDATE_DESCRIPTION, description });
  dispatch({ type: UPDATE_INSTRUCTIONS, instructions });
  dispatch({ type: GET_TOTAL_PRICE_REQUEST });
  dispatch({ type: CHANGE_SELECTED_PRODUCT, selectedProduct });
  dispatch({ type: CHANGE_SELECTED_TAG, selectedTags });
  dispatch({ type: GET_SALES_TAX_SUCCESS, salesTax });
  dispatch({ type: GET_BATCH_SIZE_SUCCESS, batchSize });
  dispatch({ type: GET_SHIPPING_SUCCESS, shipping });
  dispatch({ type: CHANGE_MARKUP_FACTOR, markupFactor });
  dispatch({ type: GET_DEVELOPMENT_HOURS_SUCCESS, developmentHours });
  dispatch({ type: GET_HOURLY_RATE_SUCCESS, hourlyRate });

  //    setStockBlendMode(stock_blend)
  dispatch(changeSelectedRecipe(selectedProduct ? selectedProduct.recipe : {}));
  dispatch(changeSelectedContainer(selectedProduct ? selectedProduct.container : {}));
  dispatch(saveScaledBy(scaledBy));
  dispatch(getTotalPrice());

  dispatch(changeCurrency(selectedProduct.currency.name));
  dispatch({ type: STOCK_BLEND_MODE, stock_blend });
};

export const changeSelectedTag = (selectedTags) => (dispatch, getState) => {
  dispatch({ type: CHANGE_SELECTED_TAG, selectedTags });
};

export const currencyClicked = (row) => (dispatch) => {
  if (!row.id === "retail-price") return;
  dispatch({ type: CHANGE_RETAIL_PRICE });
};

export const editRetailPrice = (value) => (dispatch, getState) => {
  const { totalPrice, salesTax, shipping } = getState().product;
  totalPrice.retail_price = value;
  totalPrice.retail_price_with_tax = parseFloat(value + (value * salesTax) / 100 + parseFloat(shipping));
  dispatch({ type: EDITING_RETAIL_PRICE, totalPrice });
};

export const retailPriceUpdated = () => (dispatch, getState) => {
  const { totalPrice } = getState().product;
  const markupFactor = parseFloat(totalPrice.retail_price / totalPrice.wholesale_price);
  dispatch({ type: RETAIL_PRICE_UPDATED, totalPrice });
  dispatch({ type: CHANGE_MARKUP_FACTOR, markupFactor });
};

export const getHomePageData =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    return fetch("/pands/homepagedata/", {
      method: "GET",
      token,
      success: (res) => {
        dispatch({ type: GET_HOME_PAGE_DATA, res });
      },

      failure: (err) => {
        console.log("--------------> HOME PAGE DATA error = ", err);
      },
    });
  };

export const getProducts =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: GET_PRODUCT_REQUEST, params });
    dispatch(loadingProducts());
    const { token } = dispatch(getToken());
    let { search, ordering } = getState().product;
    const page = params.page ? params.page : undefined;
    const page_size = params.page_size ? params.page_size : MODAL_PAGE_SIZE;
    const found = params.found ? params.found : undefined;
    search = params.name ? params.name : search;
    const apiCall = params.handleProductDelete ? "products" : "productids";
    let typ = "aromatherapy";
    if (process.env.BROWSER && cosmodeRegexTest.test(history.location.pathname)) {
      typ = "cosmode";
    }
    return fetch(
      `/pands/${apiCall}/?${qs.stringify({
        search,
        ordering,
        page,
        page_size,
        found,
        typ,
      })}`,
      {
        method: "GET",
        token,
        success: (res) => {
          const count = res.count;
          const { results, next } = res;
          if (params.ispush) dispatch({ type: PUSH_PRODUCTS, results });
          else dispatch({ type: GET_PRODUCT_SUCCESS, res, count });
          dispatch({ type: CHECK_IF_PRODUCT_EXIST, next });
          const value = false;
          dispatch({ type: CHANGE_PRODUCTSLOAD, value });
        },

        // eslint-disable-next-line node/handle-callback-err
        failure: (err) => {
          dispatch({ type: GET_PRODUCT_FAILURE });
        },
      }
    );
  };

export const getUserCategories =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    return fetch("/pands/usercats", {
      method: "GET",
      token,
      success: (res) => {
        dispatch({ type: GET_USER_CATEGORIES, res });
      },

      failure: (err) => {
        console.log("--------------> err = ", err);
      },
    });
  };

export const getModalProducts =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: GET_PRODUCT_REQUEST, params });
    const { token } = dispatch(getToken());
    let { search, ordering } = getState().product;
    const page = params.page ? params.page : undefined;
    const page_size = params.page_size ? params.page_size : MODAL_PAGE_SIZE;
    const found = params.found ? params.found : undefined;
    search = params.name ? params.name : search;

    return fetch(
      `/pands/products/?${qs.stringify({
        search,
        ordering,
        page,
        page_size,
        found,
      })}`,
      {
        method: "GET",
        token,
        success: (res) => {
          const count = res.count;
          const { results, next } = res;
          if (params.ispush) dispatch({ type: PUSH_PRODUCTS, results });
          else dispatch({ type: GET_PRODUCT_SUCCESS, res, count });
          dispatch({ type: CHECK_IF_PRODUCT_EXIST, next });
          const value = false;
          dispatch({ type: CHANGE_PRODUCTSLOAD, value });
        },

        // eslint-disable-next-line node/handle-callback-err
        failure: (err) => {
          dispatch({ type: GET_PRODUCT_FAILURE });
        },
      }
    );
  };

export const getFilteredProductIDs =
  (params, filters) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: GET_PRODUCT_REQUEST, params });
    const { token } = dispatch(getToken());
    const page = params.page ? params.page : undefined;
    const page_size = params.page_size ? params.page_size : MODAL_PAGE_SIZE;

    const filtered = removeFalsy(filters);
    let params_qs = "";
    let filter_qs = "";

    if (_.isEmpty(params)) {
      params_qs = params_qs;
    } else {
      params_qs = new URLSearchParams(params).toString();
    }

    if (_.isEmpty(filtered)) {
      filter_qs = filter_qs;
    } else {
      filter_qs = new URLSearchParams(filtered).toString();
    }

    const qs = params_qs + "&" + filter_qs;
    return fetch(`/pands/productcards/?${qs}`, {
      method: "GET",
      token,
      success: (res) => {
        const count = res.count;
        const { results, next } = res;
        if (params.ispush) dispatch({ type: PUSH_PRODUCTS, results });
        else dispatch({ type: GET_PRODUCT_SUCCESS, res, count });
        dispatch({ type: CHECK_IF_PRODUCT_EXIST, next });
        const value = false;
        dispatch({ type: CHANGE_PRODUCTSLOAD, value });
      },

      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: GET_PRODUCT_FAILURE });
      },
    });
  };

export const getProductDetail =
  (id) =>
  (dispatch, getState, { fetch }) => {
    const { selectedRecipe } = getState().recipe;
    const res = { res: {} };
    dispatch({ type: GET_PRODUCT_DETAIL, res });
    const { token } = dispatch(getToken());

    // no product id found
    const regex = /[0-9]+/;
    if (!regex.test(id)) {
      return;
    }

    return fetch(`/pands/products/${id}/`, {
      method: "GET",
      token,
      success: (res) => {
        try {
          dispatch(setHydrousMode(res.hydrous));
        } catch (error) {
          // Expected output: ReferenceError: nonExistentFunction is not defined
          // (Note: the exact output may be browser-dependent)
        }
        dispatch(updateCosmeticMode(res));
        dispatch({ type: GET_PRODUCT_DETAIL, res });
        dispatch(getTotalPrice());
        dispatch(dispatchUpdateSelectedRecipe(res.recipe));
        dispatch(updateCosmeticTotalRecipeQuantity(res.recipe.total));
        dispatch(updateNewRecipeName(res.name));
        dispatch(handleBatchSizeChange(res.batch_size));
        if (res.typ == "cosmode") {
          dispatch(updateSelectedIFRACategory(res.recipe.ifracat));
        }
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: GET_PRODUCT_FAILURE });
      },
    });
  };

export const handleDeleteAll =
  (ids, pagenumber) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    const { selectedProduct } = getState().product;
    if (selectedProduct && ids.indexOf(selectedProduct.id) > -1) {
      dispatch({ type: CLEAR_FORMS });
    }
    return fetch("/pands/deleteproducts/", {
      method: "DELETE",
      token,
      body: {
        ids: ids,
      },
      success: (res) => {
        message.success("Successfully deleted all selected Products", SHORT_MESSAGE_DELAY);
        dispatch(
          getProducts({
            page: pagenumber,
            page_size: PAGE_SIZE,
            handleProductDelete: true,
          })
        );
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        message.error("Deleting failed, Please try later", SHORT_MESSAGE_DELAY);
      },
    });
  };

export const getUserProductCount =
  (ids) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    return fetch("/pands/getuserproductcount/", {
      method: "GET",
      token,
      success: (res) => {
        dispatch({ type: PRODUCT_COUNT_SUCCESS, res });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {},
    });
  };

export const handleDeleteUserProducts =
  (ids) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    return fetch("/pands/deleteuserproducts/", {
      method: "DELETE",
      token,
      body: {
        ids: ids,
      },
      success: (res) => {
        message.success("Deleted all selected Products", SHORT_MESSAGE_DELAY);
        dispatch(
          getProducts({
            page: 1,
            page_size: PAGE_SIZE,
            handleProductDelete: true,
          })
        );
        dispatch({ type: PRODUCT_COUNT_SUCCESS, res });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        message.error("Deleting failed, Please try later", SHORT_MESSAGE_DELAY);
      },
    });
  };

export const getTags =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: GET_TAGS_REQUEST, params });
    const { token } = dispatch(getToken());
    const page = params.page ? params.page : undefined;
    //  const page_size = params.page_size ? params.page_size:undefined
    const page_size = 100;
    const found = params.found ? params.found : undefined;
    const search = params.search ? params.search : undefined;
    return fetch(
      `/pands/tags/?${qs.stringify({
        search,
        page,
        page_size,
        found,
      })}`,
      {
        method: "GET",
        token,
        success: (res) => {
          const count = res.count;
          if (params.found) {
            let value = false;
            if (res.results.length > 0) {
              value = true;
            }
            dispatch({ type: TAG_EXIST, value });
            return;
          }
          const { results, next } = res;
          if (!search) dispatch({ type: SET_DEFAULT_TAGS, results });
          if (params.ispush) dispatch({ type: PUSH_TAGS, results });
          else dispatch({ type: GET_TAGS_SUCCESS, res, count });
          dispatch({ type: CHECK_IF_TAGS_EXIST, next });
          const value = false;
          dispatch({ type: CHANGE_TAGSLOAD, value });
        },
        // eslint-disable-next-line node/handle-callback-err
        failure: (err) => dispatch({ type: GET_TAGS_FAILURE }),
      }
    );
  };

export const deleteProduct =
  (id) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    return fetch(`/pands/productids/${id}/`, {
      method: "DELETE",
      token,
      success: (res) => {
        dispatch(getProducts());
        message.success("Deleted recipe successfully", SHORT_MESSAGE_DELAY);
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        message.error("Unable to delete recipe", SHORT_MESSAGE_DELAY);
      },
    });
  };

export const loadProduct = (selectedProduct) => (dispatch, getState) => {
  let productid = "";
  // select a product from the url path
  // e.g.: http://localhost:3000/product/2605
  if (selectedProduct.id) {
    productid = selectedProduct.id;
  } else {
    productid = selectedProduct;
  }
  dispatch(getProductDetail(productid));
};

// eslint-disable-next-line camelcase
export const addProduct =
  (recipe_values, container_values, product_values) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: ADD_PRODUCT_REQUEST });
    const { token } = dispatch(getToken());
    const { apiUrl, currency } = getState().global;
    const { totalPrice, retail_price_with_tax } = getState().product;
    const { cosmetic, cosmeticRecipeUnit } = getState().rawMaterials;
    const {
      selectedTags,
      notes,
      label,
      image,
      description,
      instructions,
      salesTax,
      shipping,
      markupFactor,
      developmentHours,
      hourlyRate,
      stock_blend,
      save_as_cosmetic,
      batchSize,
      cosmode,
    } = getState().product;
    const { selectedIFRACategory, hydrous } = getState().ifra;
    // eslint-disable-next-line camelcase
    //let mod_retail_price_with_tax = totalPrice ? retail_price_with_tax : null;

    // eslint-disable-next-line camelcase
    let shipping_usd_price = shipping;

    if (currency.rate !== 1) {
      // eslint-disable-next-line camelcase
      //retail_price_with_tax = parseFloat(parseFloat(mod_retail_price_with_tax / currency.rate) + parseFloat(shipping));
      // eslint-disable-next-line camelcase
      shipping_usd_price = parseFloat(parseFloat(shipping / currency.rate));
    }

    dispatch(dispatchNewRecipeRequest());
    dispatch(dispatchNewContainerRequest());

    let recipe_body;
    if (cosmetic) {
      const { selectedRawMaterials, fields, cosmeticTotalRecipeQuantity } = getState().rawMaterials;
      //        const total = fields.recipe_total;
      const total = cosmeticTotalRecipeQuantity;
      const totalunit = recipe_values.recipe_totalunit;

      recipe_body = {
        cosmetic: true,
        total: total,
        totalunit: totalunit,
        ingredients: selectedRawMaterials.map((item) => {
          const matchedIngredient = _.compact(recipe_values.ingredients).find((v) => v.raw_material === item.raw_material.id);
          return {
            quantity: item.quantity,
            raw_material: item.raw_material.id,
            phase: item.phase ? item.phase : "A",
          };
        }),

        // ingredients: selectedRawMaterials.map((item) =>
        //   _.compact(recipe_values.ingredients).find((v) => v.raw_material === item.raw_material.id)
        // ),
        ifracat: selectedIFRACategory,
      };
      recipe_body.ingredients.map((item) => {
        item.unit = cosmeticRecipeUnit;
        return selectedRawMaterials.find((element) => (element.id = item.raw_material));
      });
    } else {
      // convert recipe_values (selected ingredients) into raw_materials
      const { selectedRawMaterials } = getState().rawMaterials;
      // eslint-disable-next-line camelcase
      recipe_body = {
        cosmetic: false,
        ingredients: selectedRawMaterials.map((item) =>
          _.compact(recipe_values.ingredients).find((v) => v.raw_material === item.raw_material.id)
        ),
      };
    }

    // convert container_values (selected components) into an array
    const { selectedComponents } = getState().components;
    // eslint-disable-next-line camelcase
    const container_body = {
      components: selectedComponents.map((item) => _.compact(container_values.components).find((v) => v.component === item.component.id)),
      name: container_values.name,
    };

    let saveAsCosmeticMode = save_as_cosmetic;
    let currentModeIsCosmode = false;
    if (process.env.BROWSER && cosmodeRegexTest.test(history.location.pathname)) {
      currentModeIsCosmode = true;
      saveAsCosmeticMode = false;
    }

    // eslint-disable-next-line camelcase
    const product_body = {
      name: product_values.name,
      development_hours: developmentHours,
      hourly_rate: hourlyRate,
      markup_factor: markupFactor,
      instructions: instructions,
      notes: notes,
      label: label,
      image: image,
      currency: currency.key,
      description: description,
      tags: selectedTags && selectedTags.length > 0 ? selectedTags.map((tag) => tag.id) : [],
      sales_tax: salesTax,
      batch_size: batchSize,
      shipping: shipping_usd_price,
      total_price: retail_price_with_tax,
      stock_blend: stock_blend,
      save_as_cosmetic: saveAsCosmeticMode,
    };

    // Warning: order matters here between cosmetic and cosmode
    if (currentModeIsCosmode) {
      product_body.typ = "cosmode";
    } else if (cosmode) {
      product_body.typ = "cosmode";
    } else if (cosmetic) {
      product_body.typ = "cosmetic";
    } else {
      product_body.typ = "aromatherapy";
    }
    product_body.hydrous = hydrous;

    return axios({
      method: "post",
      url: apiUrl + "/pands/products/",
      data: {
        product: product_body,
        recipe: recipe_body,
        container: container_body,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        const { recipe, container } = response.data;
        const selectedProduct = response.data;
        const res = response.data;
        dispatch({ type: GET_PRODUCT_DETAIL, res });
        dispatch(dispatchNewRecipeSuccess(recipe));
        dispatch(dispatchNewContainerSuccess(container));
        dispatch({ type: SET_SELECTED_PRODUCT, selectedProduct });
        dispatch({ type: ADD_PRODUCT_SUCCESS });
        message.success(messages.addProductSuccess, SHORT_MESSAGE_DELAY);
        dispatch(getProducts());
        dispatch(loadProduct(selectedProduct));
        dispatch(saveAsCosmetic(false));
        return response;
      })
      .catch((error) => {
        dispatch({ type: ADD_PRODUCT_FAILURE });
        dispatch(dispatchNewRecipeFailure(error));
        dispatch(dispatchNewContainerFailure(error));
        message.error(messages.addProductError, LONG_MESSAGE_DELAY);
        dispatch(getProducts());
        dispatch(saveAsCosmetic(false));
        return Promise.reject(error);
      });
  };

export const productExists = (name) => (dispatch, getState) => {
  const { token } = dispatch(getToken());
  const { apiUrl } = getState().global;

  return axios({
    method: "post",
    url: apiUrl + "/pands/productexists/",
    data: { name: name },
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const updateProductID = (selectedProduct) => (dispatch, getState) => {
  let { products } = getState().product;
  products = products.map((product) => {
    if (product.name === selectedProduct.name) {
      product.id = selectedProduct.id;

      return product;
    }
    return product;
  });

  dispatch({ type: UPDATE_PRODUCT_ID, products });
};

// eslint-disable-next-line camelcase
export const updateProduct =
  (recipe_values, container_values, product_values) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });
    const { token } = dispatch(getToken());
    const { apiUrl, currency } = getState().global;
    const { cosmetic, cosmeticRecipeUnit } = getState().rawMaterials;
    const {
      selectedProduct,
      notes,
      label,
      image,
      description,
      instructions,
      newRecipeName,
      markupFactor,
      developmentHours,
      hourlyRate,
      products,
      selectedTags,
      salesTax,
      shipping,
      stock_blend,
      batchSize,
      cosmode,
    } = getState().product;
    const { selectedIFRACategory, hydrous } = getState().ifra;

    const { selectedContainer } = getState().container;
    // eslint-disable-next-line camelcase
    let { retail_price_with_tax } = getState().product.totalPrice ? getState().product.totalPrice : 0;
    selectedProduct.development_hours = developmentHours;
    selectedProduct.hourly_rate = hourlyRate;
    selectedProduct.markup_factor = markupFactor;
    selectedProduct.sales_tax = salesTax;
    selectedProduct.batch_size = batchSize;
    selectedProduct.currency = currency.key;
    selectedProduct.shipping = shipping;
    selectedProduct.tags = selectedTags;
    // eslint-disable-next-line no-use-before-define
    selectedProduct.recipe = selectedRecipe;
    selectedProduct.container = selectedContainer;
    // eslint-disable-next-line camelcase
    selectedProduct.total_price = retail_price_with_tax;
    // eslint-disable-next-line camelcase
    let shipping_usd_price = shipping;

    // convert recipe_values (selected ingredients) into raw_materials
    dispatch(dispatchUpdateRecipeRequest());
    const { selectedRawMaterials, cosmeticTotalRecipeQuantity } = getState().rawMaterials;
    const { selectedRecipe } = getState().recipe;
    updateRecipeState(selectedRawMaterials, recipe_values);
    selectedRecipe.ingredients = selectedRawMaterials;
    dispatch(dispatchUpdateSelectedRecipe(selectedRecipe));
    let recipe_body;
    if (cosmetic) {
      //        const total = parseFloat(recipe_values.recipe_total);
      const total = cosmeticTotalRecipeQuantity;
      const totalunit = recipe_values.recipe_totalunit;

      recipe_body = {
        cosmetic: true,
        ingredients: selectedRawMaterials.map((item) => {
          const matchedIngredient = _.compact(recipe_values.ingredients).find((v) => v.raw_material === item.raw_material.id);
          return {
            quantity: item.quantity,
            raw_material: item.raw_material.id,
            phase: item.phase ? item.phase : "A",
          };
        }),
        action: "replace",
        ifracat: selectedIFRACategory,
      };

      recipe_body.ingredients.map((item) => {
        item.unit = cosmeticRecipeUnit ? cosmeticRecipeUnit : "g";
        return selectedRawMaterials.find((element) => (element.id = item.raw_material));
      });
      recipe_body.total = total;
      recipe_body.totalunit = totalunit;
    } else {
      recipe_body = {
        cosmetic: false,
        ingredients: selectedRawMaterials.map((item) =>
          _.compact(recipe_values.ingredients).find((v) => v.raw_material === item.raw_material.id)
        ),
        action: "replace",
      };
    }
    // convert container_values (selected components) into an array
    let selectedContainerObj = _.cloneDeep(getState().container.selectedContainer);
    const selectedComponentsObj = _.cloneDeep(getState().components.selectedComponents);

    let containerBody = {};

    addOrUpdateContainerState(selectedContainerObj.components, container_values.components);

    for (let component = 0; component < selectedComponentsObj.length; component++) {
      if (!("id" in selectedComponentsObj[component])) {
        selectedComponentsObj[component].id = Math.floor(Math.random() * 100);
      }
      for (let info = 0; info < container_values.components.length; info++) {
        if (container_values.components[info]) {
          if (selectedComponentsObj[component].component.id === container_values.components[info].component) {
            selectedComponentsObj[component].quantity = container_values.components[info].quantity;
          }
        }
      }
      if (!selectedComponentsObj[component].type) {
        selectedComponentsObj[component].type = "secondary";
      }
    }
    // we have to make changes below
    const temporarayArray = selectedContainerObj;
    selectedContainerObj = selectedComponentsObj;

    // dispatch({ type: UPDATE_CONTAINER, selectedContainer });
    dispatch(updateSelectedProduct(selectedContainerObj));
    selectedContainerObj = temporarayArray;
    const tempArray = selectedComponentsObj.map((item) =>
      _.compact(container_values.components).find((v) => v.component === item.component.id)
    );

    if (selectedComponentsObj.length == 0) {
      var component_id_objs = selectedContainerObj.components.map(function (item) {
        return { component: item.id };
      });
      containerBody = {
        components: component_id_objs,
        action: "delete",
      };
    } else {
      containerBody = {
        components: tempArray,
        action: "replace",
      };
    }

    const updatedProductList = products.map((item) => {
      if (item.id === selectedProduct.id) {
        selectedProduct.container = item.container;
        return selectedProduct;
      }
      return item;
    });

    dispatch({ type: MODIFY_SELECTED_PRODUCT, updatedProductList });
    if (currency.rate !== 1) {
      // eslint-disable-next-line camelcase
      retail_price_with_tax = parseFloat(retail_price_with_tax / currency.rate);
      // eslint-disable-next-line camelcase
      shipping_usd_price = parseFloat(shipping / currency.rate);
    }
    const productBody = {
      development_hours: developmentHours,
      hourly_rate: hourlyRate,
      markup_factor: markupFactor,
      product: selectedProduct.id,
      name: newRecipeName,
      recipe: selectedRecipe.id,
      container: selectedContainer.id,
      tags: selectedTags && selectedTags.length > 0 ? selectedTags.map((tag) => tag.id) : [],
      sales_tax: salesTax,
      batch_size: batchSize,
      shipping: shipping_usd_price,
      total_price: retail_price_with_tax ? retail_price_with_tax : 0.0,
      currency: currency.key,
      notes: notes,
      label: label,
      image: image,
      description: description,
      instructions: instructions,
      stock_blend: stock_blend,
    };

    // Warning: order matters here between cosmetic and cosmode
    if (cosmode) {
      productBody.typ = "cosmode";
    } else if (cosmetic) {
      productBody.typ = "cosmetic";
    } else {
      productBody.typ = "aromatherapy";
    }
    productBody.hydrous = hydrous;

    return axios({
      method: "patch",
      url: apiUrl + `/pands/products/${selectedProduct.id}/`,
      data: {
        product: productBody,
        recipe: recipe_body,
        container: containerBody,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        const selectedProduct = response.data;
        dispatch(dispatchUpdateRecipeSuccess(response));
        dispatch(dispatchUpdateContainerSuccess(response));
        dispatch({ type: UPDATE_PRODUCT_SUCCESS, selectedProduct });
        dispatch({ type: SET_SELECTED_PRODUCT, selectedProduct });
        message.success(messages.updateProductSuccess, SHORT_MESSAGE_DELAY);
        //          dispatch(saveAsCosmetic(false));
        dispatch(getProducts());
        return response;
      })
      .catch((error) => {
        dispatch(dispatchUpdateRecipeFailure(error));
        dispatch(dispatchUpdateContainerFailure(error));
        dispatch({ type: UPDATE_PRODUCT_FAILURE });
        message.error(messages.updateProductError, LONG_MESSAGE_DELAY);
        //          dispatch(saveAsCosmetic(false));
        dispatch(getProducts());

        return Promise.reject(error);
      });
  };

// eslint-disable-next-line camelcase
export const addMultipleTagsToProduct =
  (product_id, tag_ids) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });
    const { token } = dispatch(getToken());
    const { apiUrl, currency } = getState().global;

    if (tag_ids.length == 0) {
      return;
    }

    if (!product_id) {
      return;
    }

    return axios({
      method: "patch",
      url: apiUrl + `/pands/updatetags/${product_id}/`,
      data: {
        tags: tag_ids,
        product: product_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(messages.updateProductError, LONG_MESSAGE_DELAY);
        return Promise.reject(error);
      });
  };

// eslint-disable-next-line camelcase
export const removeMultipleTagsFromProduct =
  (product_id, tag_ids) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });
    const { token } = dispatch(getToken());
    const { apiUrl, currency } = getState().global;

    if (tag_ids.length == 0) {
      return;
    }

    if (!product_id) {
      return;
    }

    return axios({
      method: "patch",
      url: apiUrl + `/pands/removetags/${product_id}/`,
      data: {
        tags: tag_ids,
        product: product_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        message.error(messages.updateProductError, LONG_MESSAGE_DELAY);
        return Promise.reject(error);
      });
  };

const getElementPrice = (priceArray, currency) => {
  let totalCurrency = 0;

  priceArray.forEach((price) => {
    if (price.currencies) {
      totalCurrency += parseFloat(price.currencies[currency && currency.key]);
    }
  });
  return parseFloat(totalCurrency);
};

export const getTotalPrice =
  () =>
  (dispatch, getState, { fetch }) => {
    const { currencies, currency } = getState().global;
    const { user } = getState().user;
    let { developmentHours, hourlyRate, markupFactor, salesTax, shipping } = getState().product;
    const containerPrice = getElementPrice(getState().container.prices, currency);
    const recipePrice = getElementPrice(getState().recipe.prices, currency);

    let developmentCost = currency ? (hourlyRate * parseFloat(developmentHours)) / 60 : 0.0;
    let converted = developmentCost;
    if (currency && user) {
      converted = convertCurrencyValue(currencies, user.preferences.currency, developmentCost, currency.key);
    }

    if (converted) {
      developmentCost = converted;
    }

    if (markupFactor === 0) {
      markupFactor = 1;
    }

    let totalPrice = {};
    if (containerPrice > 0 || recipePrice > 0) {
      totalPrice.recipePrice = recipePrice;
      totalPrice.containerPrice = containerPrice;
      totalPrice.wholesale_price = parseFloat(parseFloat(recipePrice + containerPrice));
      totalPrice.recipePrice = recipePrice;

      totalPrice.retail_price = parseFloat(parseFloat(markupFactor * (totalPrice.wholesale_price + developmentCost)));
      totalPrice.salesTax = totalPrice.retail_price * (salesTax / 100);
      totalPrice.developmentCost = developmentCost;
      const shipping2 = parseFloat(!shipping ? 0 : shipping);
      totalPrice.retail_price_with_tax = parseFloat(totalPrice.retail_price + totalPrice.salesTax + shipping2);
      totalPrice.profit = totalPrice.retail_price_with_tax - shipping2 - totalPrice.salesTax - totalPrice.wholesale_price - developmentCost;
      dispatch({ type: GET_TOTAL_PRICE_SUCCESS, totalPrice });
    } else {
      totalPrice = 0;
      dispatch({ type: GET_TOTAL_PRICE_FAILURE, totalPrice });
    }
  };

// FIX: this is a copy and paste and slightly modified version of
// getTotalPrice
export const getTotalPriceOfProduct =
  (product) =>
  (dispatch, getState, { fetch }) => {
    const { currencies, currency } = getState().global;
    const { user } = getState().user;
    //   let { developmentHours, hourlyRate, markupFactor, salesTax, shipping } = product;
    //   const containerPrice = getElementPrice(product.container.prices, currency);
    // const recipe_prices = getPriceData(product.recipe)

    const containerPrice = getElementPrice(getState().container.prices, currency);
    const recipePrice = getElementPrice(getState().recipe.prices, currency);
    //      const recipePrice = getElementPrice(product.recipeprices, currency);

    let developmentCost = currency ? (product.hourlyRate * parseFloat(product.developmentHours)) / 60 : 0.0;
    let converted = developmentCost;
    if (currency && user) {
      converted = convertCurrencyValue(currencies, user.preferences.currency, developmentCost, currency.key);
    }

    if (converted) {
      developmentCost = converted;
    }

    let markupFactor = product.markupFactor;
    if (product.markupFactor === 0) {
      markupFactor = 1;
    }

    let totalPrice = {};

    if (containerPrice > 0 || recipePrice > 0) {
      totalPrice.recipePrice = recipePrice;
      totalPrice.containerPrice = containerPrice;
      totalPrice.wholesale_price = parseFloat(parseFloat(recipePrice + containerPrice));
      totalPrice.recipePrice = recipePrice;

      totalPrice.retail_price = parseFloat(parseFloat(markupFactor * (totalPrice.wholesale_price + developmentCost)));
      totalPrice.salesTax = totalPrice.retail_price * (product.salesTax / 100);
      totalPrice.developmentCost = developmentCost;
      const shipping2 = parseFloat(!product.shipping ? 0 : product.shipping);
      totalPrice.retail_price_with_tax = parseFloat(totalPrice.retail_price + totalPrice.salesTax + shipping2);
      totalPrice.profit = totalPrice.retail_price_with_tax - shipping2 - totalPrice.salesTax - totalPrice.wholesale_price - developmentCost;
    } else {
      totalPrice = 0;
    }
    return totalPrice;
  };

export const changeMarkupFactor = (value) => (dispatch, getState) => {
  let markupFactor = 1;
  if (isNaN(parseFloat(value))) {
  } else {
    markupFactor = parseFloat(value);
    if (markupFactor < 1) {
      markupFactor = 1;
    }
  }

  dispatch({ type: CHANGE_MARKUP_FACTOR, markupFactor });
  dispatch(getTotalPrice());
};

export const openProductsModal = () => (dispatch, getState) => {
  dispatch({ type: OPEN_PRODUCTS_MODAL });
};

export const closeProductsModal = () => ({
  type: CLOSE_PRODUCTS_MODAL,
});

export const clear = () => ({ type: CLEAR });

export const clearAllActions = (dispatch, formRef) => {
  dispatch(clearSessionCurrency());
  dispatch(cleardilution());
  dispatch(clearContainer());
  dispatch(clearRawMaterials());
  dispatch(clearIFRA());
  dispatch(clearComponents());
  dispatch(clearOnlySelectedRecipes());
  dispatch(clearRecipe());
  formRef && formRef.current && formRef.current.resetFields && formRef.current.resetFields();
  dispatch({ type: CLEAR_FORMS });
};

export const signOut = (dispatch) => {
  clearAllActions(dispatch);
};

// const  = new RegExp("/cosmode/\d+|/product/\d+");
export const clearForms =
  (formRef) =>
  (dispatch, getState, { history }) => {
    clearAllActions(dispatch, formRef);
    if (process.env.BROWSER && idRegexTest.test(history.location.pathname)) {
      const noid = history.location.pathname.replace(/\/\d+$/, "");
      history.push(noid);
    }
  };

export const setProductNavRef = (formRef) => (dispatch) => {
  dispatch({ type: PRODUCT_NAV_REF, formRef });
};

export const handleDevelopmentHours = (value) => (dispatch, getState) => {
  let developmentHours = 0;
  if (isNaN(parseFloat(value))) {
  } else {
    developmentHours = parseFloat(value);
  }

  dispatch({ type: GET_DEVELOPMENT_HOURS_SUCCESS, developmentHours });
  dispatch(getTotalPrice());
};

export const handleHourlyRate = (value) => (dispatch, getState) => {
  let hourlyRate = 0;
  if (isNaN(parseFloat(value))) {
  } else {
    hourlyRate = parseFloat(value);
  }
  dispatch({ type: GET_HOURLY_RATE_SUCCESS, hourlyRate });
  dispatch(getTotalPrice());
};

export const handleTaxChange = (value) => (dispatch, getState) => {
  let salesTax = 0;
  if (isNaN(parseFloat(value))) {
  } else {
    salesTax = parseFloat(value);
  }
  dispatch({ type: GET_SALES_TAX_SUCCESS, salesTax });
  dispatch(getTotalPrice());
};

export const handleBatchSizeChange = (value) => (dispatch, getState) => {
  let batchSize = 1.0;
  if (isNaN(parseFloat(value))) {
  } else {
    batchSize = parseFloat(value);
  }
  dispatch({ type: GET_BATCH_SIZE_SUCCESS, batchSize });
  dispatch(getTotalPrice());
};

export const handleShippingChange = (value) => (dispatch, getState) => {
  let shipping = 0;
  if (isNaN(parseFloat(value))) {
  } else {
    shipping = parseFloat(value);
  }

  dispatch({ type: GET_SHIPPING_SUCCESS, shipping });
  dispatch(getTotalPrice());
};

export const handleCurrencyChange = () => (dispatch, getState) => {
  const { currency } = getState().global;
  let shipping = 0;
  if (currency) {
    if (!localStorage.getItem("previous-currency")) {
      localStorage.setItem("previous-currency", currency.rate);
      shipping = parseFloat(parseFloat(getState().product.shipping) * parseFloat(currency.rate));
    } else {
      shipping = parseFloat(
        parseFloat(getState().product.shipping) * (parseFloat(currency.rate) / localStorage.getItem("previous-currency"))
      );
    }
    localStorage.setItem("previous-currency", currency.rate);
    dispatch({ type: GET_SHIPPING_SUCCESS, shipping });
    dispatch(getTotalPrice());
  }
};

export const updateSelectedProduct = (selectedContainer) => (dispatch, getState) => {
  const selectedProduct = _.cloneDeep(getState().product.selectedProduct);
  const productsArray = _.cloneDeep(getState().product.products);
  let productDetail = _.cloneDeep(getState().product.productDetail);
  let containerId;
  if (productDetail && productDetail.container) {
    containerId = productDetail.container.id;
  } else {
    productDetail = selectedProduct;
    containerId = selectedProduct.container.id;
  }

  for (let productList = 0; productList < productsArray.length; productList++) {
    if (productsArray[productList].id === selectedProduct.id) {
      const containerObj = {
        components: selectedContainer,
        currency: productDetail.currency,
        id: containerId,
        name: null,
        user: null,
        total_price: 1,
      };

      productsArray[productList].container = Object.assign({}, containerObj);
    }
  }
  dispatch({
    type: UPDATE_SELECTED_PRODUCT,
    productsArray,
  });
};

export const loadingProducts = () => (dispatch, getState) => {
  let { productsLoad } = getState().product;
  productsLoad = true;
  dispatch({ type: LOADING_PRODUCT, productsLoad });
};

export const loadingTags = () => (dispatch, getState) => {
  let { tagsLoad } = getState().product;
  tagsLoad = true;
  dispatch({ type: LOADING_TAGS, tagsLoad });
};

export const recipePDFClick = (event) => (dispatch, getState) => {
  const { token } = dispatch(getToken());
  const { apiUrl } = getState().global;
  const { selectedProduct } = getState().product;
  if (!selectedProduct) {
    message.info("Please select a Recipe first", LONG_MESSAGE_DELAY);
    return;
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  return axios({
    url: apiUrl + `/pands/listrecipes/${selectedProduct.id}/`,
    headers: config.headers,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const blob = new Blob([response.data], { type: "application/pdf" });
    const fileURL = window.URL.createObjectURL(blob);
    const fileLink = document.createElement("a");

    fileLink.href = fileURL;

    //  let name = `${selectedProduct.name}.pdf`
    let name = selectedProduct.name;
    name = name.replace(/[^[a-zA-Z0-9 ]/g, "");
    name = name.replace(/ +/g, " ");
    name = name + ".pdf";
    fileLink.setAttribute("download", name);
    document.body.appendChild(fileLink);

    fileLink.click();
  });
};

export const updateProductTags = (productids, category_ids) => (dispatch, getState) => {
  const { token } = dispatch(getToken());
  const { apiUrl } = getState().global;

  return axios({
    method: "post",
    url: apiUrl + "/pands/updateproducttags/",
    data: {
      product_ids: product_ids,
      category_ids: category_ids,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response) => {
      const { recipe, container } = response.data;
      const selectedProduct = response.data;
      const res = response.data;
      dispatch(updateProductTags());
      return response;
    })
    .catch((error) => {
      dispatch({ type: ADD_PRODUCT_FAILURE });
    });
};

export const recipesDownloadClick = (event) => (dispatch, getState) => {
  const { token } = dispatch(getToken());
  const { apiUrl } = getState().global;
  return fetch(apiUrl + "/pands/download_recipes/", {
    method: "GET",
    // token,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then(async (response) => {
      const blob = new Blob([response.data], { type: "text/plain" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "download");
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};

export const changeInitialLoad = (value) => (dispatch, getState) => {
  dispatch({ type: CHANGE_PRODUCTSLOAD, value });
};
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: {
    products: false,
    addingProduct: false,
    updatingProduct: false,
    totalPrice: false,
  },
  products: [],
  listproducts: [],
  productDetail: null,
  search: null,
  tagsearch: null,
  ordering: null,
  selectedProduct: null,
  selectedTags: [],
  error: null,
  totalPrice: null,
  markupFactor: 3.0,
  developmentHours: 0,
  hourlyRate: 0,
  addRetailPrice: false,
  productsModal: false,
  categories: [],
  tags: [],
  defaultTags: [],
  tagLoading: {
    tags: false,
  },
  salesTax: 0,
  shipping: 0.0,
  productsLoad: false,
  tagsLoad: false,
  count: 0,
  initialProductLoad: true,
  initialTagstLoad: true,
  notes: "",
  label: "",
  image: "",
  description: "",
  instructions: "",
  tagExist: false,
  hasNextPage: true,
  uploadingProductImage: false,
  newRecipeName: "",
  save_as_cosmetic: false,
  batchSize: 1.0,
  usercats: {},
  homepagedata: {},
  add_new_tag: "",
  cosmode: false,
};

export default createReducer(initialState, {
  [GET_PRODUCT_REQUEST]: (state, { params }) => ({
    search: _.has(params, "search") ? params.search : state.search,
    ordering: params.sorter ? `${params.sorter.order === "descend" ? "-" : ""}${params.sorter.columnKey}` : state.ordering,
    filters: params.filters || state.filters,
    //    loading: true

    loading: {
      ...state.loading,
      products: true,
    },
  }),
  [CHANGE_PRODUCTSLOAD]: (state, { value }) => ({
    initialProductLoad: value,
    productsLoad: value,
  }),
  [CHANGE_TAGSLOAD]: (state, { value }) => ({
    initialTagstLoad: value,
  }),
  [GET_PRODUCT_SUCCESS]: (state, { res: { results, next }, count }) => ({
    products: results,
    loading: {
      ...state.loading,
      products: false,
    },
    productsLoad: false,
    count: count,
  }),
  [CLEAR_PRODUCTS]: (state, {}) => ({
    products: [],
    count: 0,
  }),
  [ADD_NEW_TAG_SUCCESS]: (state, { res }) => ({
    add_new_tag: res,
  }),
  [CHECK_IF_PRODUCT_EXIST]: (state, { next }) => ({
    hasNextPage: next,
  }),
  [CHECK_IF_TAGS_EXIST]: (state, { next }) => ({
    hasNextPage: next,
  }),
  [TAG_EXIST]: (state, action) => ({
    tagExist: action.value,
    tagLoading: {
      ...state.tagLoading,
      tags: false,
    },
  }),
  [GET_PRODUCT_FAILURE]: (state, action) => ({
    loading: {
      ...state.loading,
      products: false,
    },
    productsLoad: false,
  }),
  [SET_SELECTED_PRODUCT]: (state, { selectedProduct }) => ({
    selectedProduct: selectedProduct,
  }),
  [GET_TAGS_REQUEST]: (state, { params }) => ({
    search: _.has(params, "search") ? params.search : state.search,
    tagLoading: {
      ...state.tagLoading,
      tags: false,
    },
  }),
  [GET_TAGS_SUCCESS]: (state, { res: { results, next, count } }) => ({
    tags: results,
    tagLoading: {
      ...state.tagLoading,
      tags: false,
    },
    tagsLoad: false,
    count: count,
  }),
  [GET_TAGS_FAILURE]: (state, action) => ({
    tagLoading: {
      ...state.tagLoading,
      tags: false,
    },
    tagsLoad: false,
  }),
  [ADD_PRODUCT_REQUEST]: (state, action) => ({
    loading: {
      ...state.loading,
      addingProduct: true,
    },
  }),
  [OPEN_PRODUCTS_MODAL]: (state, action) => ({
    productsModal: true,
  }),
  [CLOSE_PRODUCTS_MODAL]: (state, action) => ({
    productsModal: false,
    search: null,
  }),
  [ADD_PRODUCT_SUCCESS]: (state) => ({
    loading: {
      ...state.loading,
      addingProduct: false,
    },
  }),
  [GET_PRODUCT_DETAIL]: (state, action) => ({
    loading: {
      ...state.loading,
    },
    totalPrice: action.res.totalPrice,
    productDetail: action.res,
  }),
  [ADD_PRODUCT_FAILURE]: (state, action) => ({
    loading: {
      ...state.loading,
      addingProduct: false,
    },
  }),
  [PUSH_PRODUCTS]: (state, action) => ({
    products: state.products.concat(action.results),
    loading: false,
  }),
  [PUSH_TAGS]: (state, action) => ({
    tags: state.tags.concat(action.results),
    tagLoading: false,
  }),
  [SET_DEFAULT_TAGS]: (state, action) => ({
    defaultTags: action.results,
  }),
  [UPDATE_PRODUCT_REQUEST]: (state, action) => ({
    loading: {
      ...state.loading,
      updatingProduct: true,
    },
  }),
  [UPDATE_PRODUCT_SUCCESS]: (state, { selectedProduct }) => ({
    selectedProduct,
    loading: {
      ...state.loading,
      updatingProduct: false,
    },
  }),
  [UPDATE_PRODUCT_FAILURE]: (state, action) => ({
    loading: {
      ...state.loading,
      updatingProduct: false,
    },
  }),
  [GET_TOTAL_PRICE_REQUEST]: (state, action) => ({
    totalPrice: null,
    loading: {
      ...state.loading,
      totalPrice: true,
    },
  }),
  [GET_TOTAL_PRICE_SUCCESS]: (state, { totalPrice }) => ({
    totalPrice,
    loading: {
      ...state.loading,
      totalPrice: false,
    },
  }),
  [GET_TOTAL_PRICE_FAILURE]: (state, { totalPrice }) => ({
    totalPrice,
    loading: {
      ...state.loading,
      totalPrice: false,
    },
  }),
  [GET_SALES_TAX_SUCCESS]: (state, { salesTax }) => ({
    salesTax,
  }),
  [GET_BATCH_SIZE_SUCCESS]: (state, { batchSize }) => ({
    batchSize,
  }),
  [GET_SHIPPING_SUCCESS]: (state, { shipping }) => ({
    shipping,
  }),
  [EDITING_RETAIL_PRICE]: (state, { totalPrice }) => ({
    totalPrice,
  }),
  [RETAIL_PRICE_UPDATED]: (state, { totalPrice }) => ({
    totalPrice,
    addRetailPrice: false,
  }),
  [CHANGE_MARKUP_FACTOR]: (state, { markupFactor }) => ({
    markupFactor,
  }),
  [GET_DEVELOPMENT_HOURS_SUCCESS]: (state, { developmentHours }) => ({
    developmentHours,
  }),
  [GET_HOURLY_RATE_SUCCESS]: (state, { hourlyRate }) => ({
    hourlyRate,
  }),
  [CHANGE_SELECTED_PRODUCT]: (state, { selectedProduct }) => ({
    selectedProduct,
  }),
  [CHANGE_SELECTED_TAG]: (state, { selectedTags }) => ({
    selectedTags,
  }),
  [CHANGE_RETAIL_PRICE]: (state) => ({
    addRetailPrice: true,
  }),
  [MODIFY_SELECTED_PRODUCT]: (state, { updatedProductList }) => ({
    products: updatedProductList,
  }),
  [ADD_NEW_PRODUCT]: (state, { products }) => ({
    products,
  }),
  [UPDATE_SELECTED_PRODUCT]: (state, { productsArray }) => ({
    // selectedProduct: selectedProductArray,
    products: productsArray,
  }),
  [UPDATE_PRODUCT_ID]: (state, { products }) => ({
    products,
  }),
  [CLEAR_FORMS]: (state, action) => ({
    selectedProduct: null,
    userProductsData: {},
    totalPrice: null,
    selectedTags: [],
    developmentHours: 0,
    salesTax: 0,
    shipping: 0,
    markupFactor: 3.0,
    search: null,
    tagsearch: null,
    ordering: null,
    initialProductLoad: true,
    instructions: "",
    notes: "",
    label: "",
    image: "",
    description: "",
    hasNextPage: true,
    newRecipeName: "",
    stock_blend: false,
    airmid: [],
    save_as_cosmetic: false,
    cosmode: "",
    productnavref: "",
    productDetail: null,
    batchSize: 1.0,
    usercats: {},
    homepagedata: {},
    add_new_tag: "",
  }),
  [LOADING_PRODUCT]: (state, { productsLoad }) => ({
    productsLoad,
  }),
  [PRODUCT_COUNT_SUCCESS]: (state, action) => ({
    userProductsData: action.res,
  }),
  [LOADING_TAGS]: (state, { tagsLoad }) => ({
    tagsLoad,
  }),
  [UPDATE_NOTES]: (state, action) => ({
    notes: action.notes,
  }),
  [UPDATE_LABEL]: (state, action) => ({
    label: action.label,
  }),
  [UPDATE_IMAGE]: (state, action) => ({
    image: action.image,
  }),
  [UPDATE_DESCRIPTION]: (state, action) => ({
    description: action.description,
  }),
  [UPDATE_INSTRUCTIONS]: (state, action) => ({
    instructions: action.instructions,
  }),
  [CLEAR]: (state, action) => RESET_STORE,
  [UPDATE_NEW_RECIPE_NAME]: (state, action) => ({
    newRecipeName: action.newRecipeName,
  }),
  [STOCK_BLEND_MODE]: (state, { value }) => ({
    stock_blend: value,
  }),
  [SAVE_AS_COSMETIC]: (state, { value }) => ({
    save_as_cosmetic: value,
  }),
  [SAVE_AS_COSMODE]: (state, { value }) => ({
    cosmode: value,
  }),
  [PRODUCT_NAV_REF]: (state, { value }) => ({
    productnavref: value,
  }),
  [GET_HOME_PAGE_DATA]: (state, action) => ({
    homepagedata: action.res,
  }),
  [GET_USER_CATEGORIES]: (state, action) => ({
    usercats: action.res,
  }),
});
