import { defineMessages } from "react-intl";

export default defineMessages({
  addTagFailed: {
    id: "notifications.addTagFailed",
    defaultMessage: "Adding a new category failed.",
  },
  updatingUserSuccess: {
    id: "notifications.updatingUserSuccess",
    defaultMessage: "Account updated.",
  },
  updatingUserPreferencesSuccess: {
    id: "notifications.updatingUserPreferencesSuccess",
    defaultMessage: "Preferences updated.",
  },
  updatingUserError: {
    id: "notifications.updatingUserError",
    defaultMessage: "Something went wrong. Please try again.",
  },
  updatingPasswordSuccess: {
    id: "notifications.updatingPasswordSuccess",
    defaultMessage: "Password changed.",
  },
  uploadFailure: {
    id: "notifications.uploadFailure",
    defaultMessage: "Uploading failed.",
  },
});
