import createReducer, { RESET_STORE } from "../createReducer";
import { getToken } from "./user";
import qs from "query-string";
import { getPrices } from "./container";
import _ from "lodash";
import axios from "../config-axios";
import { message } from "antd";
import { MODAL_PAGE_SIZE, SHORT_MESSAGE_DELAY, LONG_MESSAGE_DELAY, PAGE_SIZE } from "../constants";

// ------------------------------------
// Constants
// ------------------------------------
export const GET_COMPONENTS_REQUEST = "Components.GET_COMPONENTS_REQUEST";
export const GET_COMPONENTS_SUCCESS = "Components.GET_COMPONENTS_SUCCESS";
export const GET_COMPONENTS_FAILURE = "Components.GET_COMPONENTS_FAILURE";

export const GET_SPECIFIC_REQUEST = "Components.GET_SPECIFIC_REQUEST";
export const GET_SPECIFIC_SUCCESS = "Components.GET_SPECIFIC_SUCCESS";
export const GET_SPECIFIC_FAILURE = "Components.GET_SPECIFIC_FAILURE";

export const UPDATE_SPECIFIC_COMPONENT = "Components.UPDATE_SPECIFIC_COMPONENT";

export const OPEN_COMPONENTS_MODAL = "Components.OPEN_COMPONENTS_MODAL";
export const CLOSE_COMPONENTS_MODAL = "Components.CLOSE_COMPONENTS_MODAL";

export const CHANGE_COMPONENT_SEARCH_MODAL = "Components.CHANGE_COMPONENT_SEARCH_MODAL";
export const CHANGE_SELECTED_ROWS = "Components.CHANGE_SELECTED_ROWS";

export const CHANGE_SELECTED_COMPONENTS = "Components.CHANGE_SELECTED_COMPONENTS";

export const SAVE_FIELDS = "Components.SAVE_FIELDS";

export const CLEAR_SELECTED_COMPONENTS = "Components.CLEAR_SELECTED_COMPONENTS";
export const CLEAR = "Components.CLEAR";

export const LOADING_COMPONENTS = "Components.LOADING_COMPONENTS";

export const DELETE_COMPONENT_REQUEST = "Components.DELETE_COMPONENT_REQUEST";
export const DELETE_COMPONENT_SUCCESS = "Components.DELETE_COMPONENT_SUCCESS";
export const DELETE_COMPONENT_FAILURE = "Components.DELETE_COMPONENT_FAILURE";

export const GET_CATEGORIES_COMPONENT_REQUEST = "Components.GET_CATEGORIES_COMPONENT_REQUEST";

export const GET_CATEGORIES_COMPONENT_SUCCESS = "Components.GET_CATEGORIES_COMPONENT_SUCCESS";

export const GET_CATEGORIES_COMPONENT_FAILURE = "Components.GET_CATEGORIES_COMPONENT_FAILURE";

export const PUSH_COMPONENTS = "Components.PUSH_COMPONENTS";
export const UPDATE_COUNT = "Components.UPDATE_COUNT";

export const SET_ONLY_MINE = "Components.SET_ONLY_MINE";

export const SET_ONLY_BIZ = "Components.SET_ONLY_BIZ";

export const SET_ONLY_INVENTORY_MINE = "Components.SET_ONLY_MINE";

export const TOGGLE_ONLY_MINE = "Components.TOGGLE_ONLY_MINE";

export const SELECTED_COMPONENT_CATEGORY = "Components.SELECTED_COMPONENT_CATEGORY";

export const GET_RAW_SUPPLIER_REQUEST = "Components.GET_RAW_SUPPLIER_REQUEST";
export const GET_RAW_SUPPLIER_SUCCESS = "Components.GET_RAW_SUPPLIER_SUCCESS";
export const GET_RAW_SUPPLIER_FAILURE = "Components.GET_RAW_SUPPLIER_FAILURE";

export const CLOSE_SHOW_COMPONENTS_DRAWER = "Components.CLOSE_SHOW_COMPONENTS_DRAWER";
export const OPEN_SHOW_COMPONENTS_DRAWER = "Components.OPEN_SHOW_COMPONENTS_DRAWER";

// ------------------------------------
// Actions
// ------------------------------------

export const setOnlyMineComponent = (only_mine) => (dispatch, getState) => {
  dispatch({ type: SET_ONLY_MINE, only_mine });
};

export const setOnlyBizComponent = (only_biz) => (dispatch, getState) => {
  dispatch({ type: SET_ONLY_BIZ, only_biz });
};

export const setOnlyMineInventoryComponent = (onlyMine) => (dispatch, getState) => {
  dispatch({ type: SET_ONLY_INVENTORY_MINE, onlyMine });
};

export const toggleOnlyMine = () => (dispatch, getState) => {
  dispatch({ type: TOGGLE_ONLY_MINE });
};

export const getComponents =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: GET_COMPONENTS_REQUEST, params });
    const { token } = dispatch(getToken());
    // const page = params.page ? params.page:undefined
    const page_size = params.page_size ? params.page_size : MODAL_PAGE_SIZE;
    // eslint-disable-next-line camelcase
    const { search, only_mine, only_biz, cat, page } = getState().components;
    let { ordering } = getState().components;
    const requested_ordering = params.ordering ? String(params.ordering) : undefined;
    if (requested_ordering) ordering = requested_ordering;

    return fetch(
      `/pands/components/?${qs.stringify({
        search,
        ordering,
        page,
        page_size,
        only_mine,
        only_biz,
        cat,
      })}`,
      {
        method: "GET",
        token,
        success: (res) => {
          const components = res.results;

          if (params.ispush) {
            dispatch({ type: PUSH_COMPONENTS, components });
          } else {
            dispatch({ type: GET_COMPONENTS_SUCCESS, res, next: res.next });
          }
          const count = res.count;
          dispatch({ type: UPDATE_COUNT, count });
        },
        // eslint-disable-next-line node/handle-callback-err
        failure: (err) => dispatch({ type: GET_COMPONENTS_FAILURE }),
      }
    );
  };

export const makeTheseComponentsMine =
  (components) =>
  async (dispatch, getState, { fetch }) => {
    const component_ids = {
      ids: components.map((item) => {
        return item.id;
      }),
    };

    const { apiUrl } = getState().global;
    const { token } = dispatch(getToken());

    try {
      const response = await axios.post(apiUrl + "/pands/clone_components/", component_ids, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      });
      dispatch(getComponents());
      return response;
    } catch (error) {
      console.log(typeof error);
      console.log(error);
      message.error("Duplicate, already exists!", SHORT_MESSAGE_DELAY);
    }
  };

export const getSuppliersForTable =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: GET_RAW_SUPPLIER_REQUEST });
    const { token } = dispatch(getToken());
    const search = params.search ? params.search : undefined;
    const page = params.page ? params.page : 1;
    const page_size = PAGE_SIZE;
    return fetch(
      `/pands/suppliers/?${qs.stringify({
        search,
        page,
        page_size,
      })}`,
      {
        method: "GET",
        token,
        success: (res) => {
          const supplier = res.results;
          const count = res.results.length;
          dispatch({ type: GET_RAW_SUPPLIER_SUCCESS, supplier, count });
        },
        // eslint-disable-next-line node/handle-callback-err
        failure: (err) => {
          dispatch({ type: GET_RAW_SUPPLIER_FAILURE });
        },
      }
    );
  };

export const updateComponentProperties =
  (id, property, currentText, propertyType) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    const actualComponents = getState().components.components;
    const requestObject = {};
    actualComponents.forEach((componentObj) => {
      if (componentObj.id !== id) return null;
      switch (propertyType) {
        case "Price":
          requestObject.price = parseFloat(currentText).toFixed(3);
          break;
        case "Quantity":
          requestObject.quantity = parseFloat(currentText).toFixed(3);
          break;
        case "Size":
          requestObject.size = currentText;
          break;
        case "Currency":
          requestObject.currency = currentText;
          break;
        case "Supplier":
          requestObject.supplier = property;
          break;
        case "Size_unit":
          requestObject.size_unit = property;
          break;
        case "Order Number":
          requestObject.ordernum = property;
          break;
        default:
          break;
      }
    });
    return fetch("/pands/componentids/" + id + "/", {
      method: "PATCH",
      token,
      body: {
        ...requestObject,
      },
      success: (res) => {
        message.success(propertyType + " Updated Successfully", SHORT_MESSAGE_DELAY);
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        message.error("Unable to update" + propertyType + ", Please try again later.", SHORT_MESSAGE_DELAY);
      },
    });
  };

export const handleDelete =
  (id) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    dispatch({ type: DELETE_COMPONENT_REQUEST });
    return fetch(`/pands/components/${id}/`, {
      method: "DELETE",
      token,
      success: (res) => {
        dispatch(getComponents());
        dispatch({ type: DELETE_COMPONENT_SUCCESS, res });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => dispatch({ type: DELETE_COMPONENT_FAILURE }),
    });
  };

export const changeComponentSearch = () => ({
  type: CHANGE_COMPONENT_SEARCH_MODAL,
});

export const openComponentsModal = () => (dispatch, getState) => {
  dispatch({ type: OPEN_COMPONENTS_MODAL });
  // dispatch(getComponents());
};

export const closeComponentsModal = () => (dispatch, getState) => {
  const { selectedComponents, only_mine } = getState().components;
  dispatch(getComponentsSelectedCategory(selectedComponents, only_mine));
  dispatch({ type: CLOSE_COMPONENTS_MODAL });
};

export const openComponentsDrawer = () => ({
  type: OPEN_SHOW_COMPONENTS_DRAWER,
});

export const closeComponentsDrawer = () => ({
  type: CLOSE_SHOW_COMPONENTS_DRAWER,
});

export const changeSelectedRows = (keys, selectedRows) => ({
  type: CHANGE_SELECTED_ROWS,
  selectedRows,
});

export const changeSelectedComponents = (selectedComponents) => (dispatch, getState) => {
  dispatch({ type: CHANGE_SELECTED_COMPONENTS, selectedComponents });
};

export const selectComponents = (newRows) => (dispatch, getState) => {
  const { selectedComponents } = getState().components;
  const newComponents = newRows.map((item) => ({ component: item }));
  dispatch(changeSelectedComponents(_.uniqBy([...selectedComponents, ...newComponents], "component.id")));
  // load whole list of items
  // dispatch(getComponents({ search: undefined }));
};

export const deselectComponent = (removedRow) => (dispatch, getState) => {
  const { selectedComponents } = getState().components;
  dispatch(changeSelectedComponents(selectedComponents.filter((item) => item.component.id !== removedRow.component.id)));
};

export const saveFields = (fields, changedFields) => (dispatch, getState) => {
  dispatch({ type: SAVE_FIELDS, fields });
  const componentsChanged =
    Object.keys(changedFields).find((key) => key.includes("components")) ||
    // if user deselects existing container
    changedFields.name === undefined;
  if (componentsChanged) {
    dispatch(getPrices(fields));
  }
};

export const clearSelectedComponent = () => (dispatch) => {
  dispatch({ type: CLEAR_SELECTED_COMPONENTS });
};
export const clear = () => ({ type: CLEAR });

export const loadingComponents = () => (dispatch, getState) => {
  let { componentsLoad } = getState().components;
  componentsLoad = true;

  dispatch({ type: LOADING_COMPONENTS, componentsLoad });
};

export const getCategoriesComponent =
  () =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    dispatch({ type: GET_CATEGORIES_COMPONENT_REQUEST });
    return fetch("/pands/components/categories/", {
      method: "GET",
      token,
      success: (res) => {
        dispatch({ type: GET_CATEGORIES_COMPONENT_SUCCESS, res });
        //    let containerID = res.filter(category => category.name === "Container")
        // dispatch(getSpecificComponents({cat:containerID[0].id}))
        dispatch(getComponents({ page: 1, search: undefined }));
      },
      failure: (res) => {
        dispatch({ type: GET_CATEGORIES_COMPONENT_FAILURE });
      },
    });
  };

// eslint-disable-next-line camelcase
export const getComponentsSelectedCategory = (selectedCategory, only_mine) => (dispatch, getState) => {
  const { categories } = getState().components;
  const category = categories.filter((i) => i.name === selectedCategory);
  // dispatch(getSpecificComponents(category[0].id}))
  if (!category.length) {
    // eslint-disable-next-line camelcase
    dispatch(
      getComponents({
        cat: undefined,
        page: 1,
        search: undefined,
        only_mine: only_mine ? 1 : 0,
      })
    );
    return;
  }
  // eslint-disable-next-line camelcase
  dispatch(
    getComponents({
      cat: category[0].id,
      page: 1,
      search: undefined,
      only_mine: only_mine ? 1 : 0,
    })
  );
};

export const updateSelectedComponent = (actualComponents) => (dispatch, getState) => {
  dispatch({ type: UPDATE_SPECIFIC_COMPONENT, actualComponents });
};

export const handleDeleteAll =
  (ids, pagenumber, alreadyExist) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    dispatch({ type: DELETE_COMPONENT_REQUEST });
    return fetch("/pands/deletecomponents/", {
      method: "DELETE",
      token,
      body: {
        ids: ids,
      },
      success: (res) => {
        dispatch(getComponents({ page: pagenumber, page_size: PAGE_SIZE }));
        dispatch({ type: DELETE_COMPONENT_SUCCESS, res });
        if (alreadyExist.length > 0) {
          return message.success("Components deleted. Note that all Blend Precisely ingredients cannot be deleted", LONG_MESSAGE_DELAY);
        }
        message.success("Deleted all selected Components");
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        message.error("Deleting failed, Please try later", SHORT_MESSAGE_DELAY);
        dispatch({ type: DELETE_COMPONENT_FAILURE });
      },
    });
  };

export const getSpecificComponents =
  (id) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    const params = {};
    dispatch({ type: GET_SPECIFIC_REQUEST, params });
    return fetch(`/pands/categories/${id}/component/`, {
      token,
      method: "GET",
      success: (res) => {
        const components = res;
        dispatch({ type: GET_SPECIFIC_SUCCESS, components });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        dispatch({ type: GET_SPECIFIC_FAILURE });
      },
    });
  };

export const setSelectedComponentCategory = (selectedComponentCategory) => (dispatch, getState) => {
  dispatch({ type: SELECTED_COMPONENT_CATEGORY, selectedComponentCategory });
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: false,
  components: [],
  search: undefined,
  ordering: undefined,
  filters: {},
  only_mine: false,
  only_biz: false,
  cat: undefined,
  page: 1,
  componentSearch: undefined,
  componentsModalOpened: false,
  selectedRows: [],
  selectedComponents: [],
  fields: {},
  componentsLoad: false,
  count: 0,
  categories: [],
  categoriesLoad: false,
  selectedComponentCategory: "all",
  suppliers: [],
  suppliersLoad: false,
  showComponentsDrawer: false,
};

export default createReducer(initialState, {
  [GET_COMPONENTS_REQUEST]: (state, { params }) => ({
    search: _.has(params, "search") ? params.search : state.search,
    ordering: params.sorter ? `${params.sorter.order === "descend" ? "-" : ""}${params.sorter.columnKey}` : state.ordering,
    filters: params.filters || state.filters,
    loading: true,
    only_mine: params.only_mine === 1 || params.only_mine === 0 ? params.only_mine : state.only_mine,
    cat: params.cat ? (params.cat === 1000 ? undefined : params.cat) : params.cat === 0 ? undefined : state.cat,
    page: params.page ? params.page : state.page,
  }),
  [SELECTED_COMPONENT_CATEGORY]: (state, action) => ({
    selectedComponentCategory: action.selectedComponentCategory,
  }),
  [GET_COMPONENTS_SUCCESS]: (state, { res: { results, next } }) => ({
    components: results,
    loading: false,
    componentsLoad: false,
    next: next,
  }),
  [UPDATE_COUNT]: (state, action) => ({ count: action.count }),
  [GET_COMPONENTS_FAILURE]: (state, action) => ({
    loading: false,
    componentsLoad: false,
  }),
  [GET_SPECIFIC_REQUEST]: (state, action) => ({
    loading: true,
    componentsLoad: true,
  }),
  [GET_SPECIFIC_SUCCESS]: (state, action) => ({
    loading: false,
    componentsLoad: false,
    components: action.components,
  }),
  [PUSH_COMPONENTS]: (state, action) => ({
    components: state.components.concat(action.components),
    loading: false,
    componentsLoad: false,
  }),
  [GET_SPECIFIC_FAILURE]: (state, action) => ({
    loading: false,
    componentsLoad: false,
  }),
  [OPEN_COMPONENTS_MODAL]: (state, action) => ({
    componentsModalOpened: true,
  }),
  [CLOSE_COMPONENTS_MODAL]: (state, action) => ({
    componentsModalOpened: false,
    selectedRows: [],
    search: undefined,
  }),
  [CHANGE_COMPONENT_SEARCH_MODAL]: (state, { componentSearch }) => ({
    componentSearch,
  }),
  [CHANGE_SELECTED_ROWS]: (state, { selectedRows }) => ({
    selectedRows,
  }),
  [CHANGE_SELECTED_COMPONENTS]: (state, { selectedComponents }) => ({
    selectedComponents,
  }),
  [SAVE_FIELDS]: (state, { fields }) => ({
    fields: {
      ...state.fields,
      ...fields,
    },
  }),
  [GET_CATEGORIES_COMPONENT_REQUEST]: (state, action) => ({
    categoriesLoad: true,
  }),
  [GET_CATEGORIES_COMPONENT_SUCCESS]: (state, action) => ({
    categoriesLoad: false,
    categories: action.res,
  }),
  [GET_CATEGORIES_COMPONENT_FAILURE]: (state, action) => ({
    categoriesLoad: false,
  }),
  [DELETE_COMPONENT_REQUEST]: (state, action) => ({
    loading: true,
  }),
  [DELETE_COMPONENT_SUCCESS]: (state, action) => ({
    loading: false,
  }),
  [DELETE_COMPONENT_FAILURE]: (state, action) => ({
    loading: false,
  }),
  [CLEAR_SELECTED_COMPONENTS]: (state) => ({
    selectedRows: [],
    selectedComponents: [],
  }),
  [LOADING_COMPONENTS]: (state, { componentsLoad }) => ({
    componentsLoad,
  }),
  [CLEAR]: (state, action) => RESET_STORE,
  [SET_ONLY_MINE]: (state, { only_mine }) => ({
    only_mine: only_mine,
  }),
  [SET_ONLY_BIZ]: (state, { only_biz }) => ({
    only_biz: only_biz,
  }),
  [SET_ONLY_INVENTORY_MINE]: (state, { onlyMine }) => ({
    only_mine: onlyMine,
    only_biz: !onlyMine,
  }),
  [TOGGLE_ONLY_MINE]: (state) => ({
    only_mine: !state.only_mine,
  }),
  [GET_RAW_SUPPLIER_REQUEST]: (state, action) => ({
    suppliersLoad: true,
  }),
  [GET_RAW_SUPPLIER_SUCCESS]: (state, action) => ({
    suppliersLoad: false,
    suppliers: action.supplier,
    totalSuppliers: action.count,
  }),
  [GET_RAW_SUPPLIER_FAILURE]: (state, action) => ({
    suppliersLoad: false,
  }),
  [UPDATE_SPECIFIC_COMPONENT]: (state, { actualComponents }) => ({
    selectedComponents: actualComponents,
  }),
  [OPEN_SHOW_COMPONENTS_DRAWER]: (state, action) => ({
    showComponentsDrawer: true,
  }),
  [CLOSE_SHOW_COMPONENTS_DRAWER]: (state, action) => ({
    showComponentsDrawer: false,
  }),
});
