import createReducer, { RESET_STORE } from "../createReducer";
import { getToken } from "./user";
import _ from "lodash";

import { message } from "antd";
import { SHORT_MESSAGE_DELAY, LONG_MESSAGE_DELAY, PAGE_SIZE } from "../constants";

//const _ = require("lodash");
const querystring = require("querystring");
// ------------------------------------
// Constants
// ------------------------------------
export const OPEN_PDF_MODAL = "RawMaterials.OPEN_PDF_MODAL";

export const CLOSE_PDF_MODAL = "RawMaterials.CLOSE_PDF_MODAL";

// ------------------------------------
// Actions
// ------------------------------------

export const openPDFModal = () => (dispatch, getState) => {
  dispatch({ type: OPEN_PDF_MODAL });
};

export const closePDFModal = () => (dispatch, getState) => {
  dispatch({ type: CLOSE_PDF_MODAL });
};

export const makeTheseRawMaterialsMine =
  (raw_materials) =>
  async (dispatch, getState, { fetch }) => {
    const rm_ids = {
      ids: raw_materials.map((item) => {
        return item.id;
      }),
    };

    const { apiUrl } = getState().global;
    const { token } = dispatch(getToken());

    try {
      const response = await axios.post(apiUrl + "/pands/clone_raw_materials/", rm_ids, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      });
      dispatch(getRawMaterials());
      return response;
    } catch (error) {
      console.log(typeof error);
      console.log(error);
      message.error("Duplicate, already exists!", SHORT_MESSAGE_DELAY);
    }
  };

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  PDFModalOpened: false,
};

export default createReducer(initialState, {
  [OPEN_PDF_MODAL]: (state) => ({
    PDFModalOpened: true,
  }),
  [CLOSE_PDF_MODAL]: (state) => ({
    PDFModalOpened: false,
  }),
});
