import createReducer, { RESET_STORE } from "../createReducer";
export const UPDATE_PERCENT = "Dilution.UPDATE_PERCENT";
export const UPDATE_PIECHART = "Dilution.UPDATE_PIECHART";
export const UPDATE_EXPLODED_PIECHART = "Dilution.UPDATE_EXPLODED_PIECHART";
export const UPDATE_PLANTPARTCHART = "Dilution.UPDATE_PLANTPARTCHART";
export const UPDATE_PLANTNOTECHART = "Dilution.UPDATE_PLANTNOTECHART";
export const UPDATE_PLANTFAMILYCHART = "Dilution.UPDATE_PLANTFAMILYCHART";
export const UPDATE_CHAKRACHART = "Dilution.UPDATE_CHAKRACHART";
export const UPDATE_STOCK_BLENDS_FOUND = "Dilution.UPDATE_STOCK_BLENDS_FOUND";
export const UPDATE_FIXED_DR = "Dilution.UPDATE_FIXED_DR";
export const CLEAR = "Dilution.CLEAR";
import { convertUnit } from "./recipe";

const addRawMaterialToDilution = (ingredient, weight, piechart, explodedpiechart, updateobj, is_stock_blend_ingredient) => {
  // eslint-disable-next-line camelcase
  const raw_ingredient = ingredient.raw_material.raw_ingredient;
  const part = raw_ingredient.part;
  const note = raw_ingredient.note;
  const family = raw_ingredient.family;
  const chakraList = raw_ingredient.chakra;

  updateDilutionCalculator(ingredient, weight, updateobj);

  const name = ingredient.raw_material.raw_ingredient.name;
  const drmax = ingredient.raw_material.raw_ingredient.drmaxpercent;
  // for normal view only add recipe ingredients to piechart
  // but not individual ingredients within a stock blend
  if (!is_stock_blend_ingredient) {
    getPieChart(piechart, name, weight, drmax);
  }
  // for exploded view add recipe ingredients only if they are not a stock blend
  if (!ingredient.raw_material.stock_blend) {
    getPieChart(explodedpiechart, name, weight, drmax);
  }
  updateobj.plantPartChart = getPlantChart(updateobj.plantPartChart, part, weight, "part");
  updateobj.plantNoteChart = getPlantChart(updateobj.plantNoteChart, note, weight, "note");
  updateobj.plantFamilyChart = getPlantChart(updateobj.plantFamilyChart, family, weight, "family");
  // eslint-disable-next-line no-return-assign

  chakraList && chakraList.map((chakra) => (updateobj.chakraChart = getPlantChart(updateobj.chakraChart, chakra, weight, "chakra")));
};

export const ingredientGrams = (ingredient, user) => {
  let grams = convertUnit(ingredient.raw_material, ingredient.quantity, ingredient.unit, user);
  return parseFloat(grams);
};

const getProduct = (ingredient) => {
  return ingredient.raw_material.stock_blend_product;
};

const addStockBlendRawMaterialToDilution = (ingredient, weight, piechart, explodedpiechart, updateobj, user) => {
  const product = getProduct(ingredient);

  if (product) {
    // calculate fractions and total grams of stock blend
    let total_grams = 0;
    let grams_hash = {};
    product.recipe.ingredients.forEach((ingredient) => {
      const grams = ingredientGrams(ingredient, user);
      grams_hash[ingredient.id] = grams;
      total_grams += grams;
    });

    product.recipe.ingredients.forEach((ingredient) => {
      // calculate the amount of a stock blend ingredient as a
      // ratio of the original stock blend times the users
      // amount
      let stock_ingredient_weight = (weight * grams_hash[ingredient.id]) / total_grams;

      // add a single stock blend ingredient
      addRawMaterialToDilution(ingredient, stock_ingredient_weight, piechart, explodedpiechart, updateobj, true);
    });
    // add the top level ingredient (not stock ingredient)
    addRawMaterialToDilution(ingredient, weight, piechart, explodedpiechart, updateobj, false);
  }
};

const getPlantChart = (plantChart, plantData, weight, type) => {
  const name = plantData && plantData.name;
  if (name) {
    const index = plantChart.findIndex((plant) => plant[0] === name);
    if (index > 0) {
      const plant = plantChart[index];
      if (plant) plant[1] = plant[1] + weight;
      plantChart[index] = plant;
    } else {
      const plant = [name, weight];
      plantChart.push(plant);
    }
  }
  return plantChart;
};

//
const getPieChart = (pieChart, name, weight, drmax) => {
  //
  const index = pieChart.findIndex((ingredient) => ingredient[0] === name);
  if (index > 0) {
    const ing = pieChart[index];
    if (ing) ing[1] = ing[1] + weight;
    pieChart[index] = ing;
  } else {
    pieChart.push([name, weight, drmax]);
  }
  return pieChart;
};

const updateDilutionCalculator = (ingredient, weight, updateobj) => {
  if (!ingredient.raw_material.stock_blend) {
    const ri = ingredient.raw_material.raw_ingredient;
    if (ri.is_eo) {
      updateobj.sumEssentialOil += weight;
    } else {
      updateobj.sumNonEssentialOil += weight;
    }
  }
};

export const resetDilutionPercent = () => (dispatch, getState) => {
  const percent = 0.0;
  dispatch({ type: UPDATE_PERCENT, percent });
};

export const updateFixedDilutionPercent = (fixedDR) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIXED_DR, fixedDR });
};

export const updateDilutionPercent = (dilutionArray) => (dispatch, getState) => {
  let percent = 2;
  const sumEssentialOil = 0;
  const sumNonEssentialOil = 0;
  const chart = ["Ingredient", "weight", "drmax"];
  const piechart = [chart];
  const explodedpiechart = [chart];
  let plantPartChart = [chart];
  let plantNoteChart = [chart];
  let plantFamilyChart = [chart];
  let chakraChart = [chart];
  const { user } = getState().user;

  const updateobj = {
    sumEssentialOil: sumEssentialOil,
    sumNonEssentialOil: sumNonEssentialOil,
    plantPartChart: plantPartChart,
    plantNoteChart: plantNoteChart,
    plantFamilyChart: plantFamilyChart,
    chakraChart: chakraChart,
    stock_blends_found: false,
  };

  const { selectedRawMaterials } = getState().rawMaterials;

  // compare user data with what is in the state
  dilutionArray.forEach((dilutionSingle) => {
    selectedRawMaterials.forEach((ingredient) => {
      if (dilutionSingle.id === ingredient.raw_material.id) {
        if (ingredient.raw_material.stock_blend) {
          updateobj.stock_blends_found = true;
          addStockBlendRawMaterialToDilution(ingredient, parseFloat(dilutionSingle.weight), piechart, explodedpiechart, updateobj, user);
        } else {
          addRawMaterialToDilution(ingredient, parseFloat(dilutionSingle.weight), piechart, explodedpiechart, updateobj);
        }
      }
    });
  });
  // chakras
  if (updateobj.chakraChart) {
    let weightedsum = parseFloat(0.0);

    updateobj.chakraChart.forEach((chart) => {
      if (typeof chart[1] === "number") {
        weightedsum += parseFloat(chart[1]);
      }
    });
    let factor = parseFloat(0.0);
    if (weightedsum > 0) {
      factor = parseFloat(updateobj.sumEssentialOil / weightedsum);
    }

    updateobj.chakraChart.forEach((chart) => {
      if (typeof chart[1] === "number") {
        chart[1] = parseFloat(chart[1] * factor);
        updateobj.chakraChart.chart = chart;
      }
    });
  }

  if (updateobj.sumEssentialOil === 0) {
    percent = 0;
  } else if (updateobj.sumNonEssentialOil === 0) {
    percent = 100;
  } else if (updateobj.sumNonEssentialOil > 0) {
    percent = (updateobj.sumEssentialOil / updateobj.sumNonEssentialOil) * 100;
  }

  plantPartChart = updateobj.plantPartChart;
  plantNoteChart = updateobj.plantNoteChart;
  plantFamilyChart = updateobj.plantFamilyChart;
  chakraChart = updateobj.chakraChart;
  const stock_blends_found = updateobj.stock_blends_found;

  dispatch({ type: UPDATE_PERCENT, percent });
  dispatch({ type: UPDATE_PIECHART, piechart });
  dispatch({ type: UPDATE_EXPLODED_PIECHART, explodedpiechart });
  dispatch({ type: UPDATE_STOCK_BLENDS_FOUND, stock_blends_found });
  dispatch({ type: UPDATE_PLANTPARTCHART, plantPartChart });
  dispatch({ type: UPDATE_PLANTNOTECHART, plantNoteChart });
  dispatch({ type: UPDATE_PLANTFAMILYCHART, plantFamilyChart });
  dispatch({ type: UPDATE_CHAKRACHART, chakraChart });
};

export const cleardilution = () => ({ type: CLEAR });

const initialState = {
  percent: 0,
  piechart: [],
  explodedpiechart: [],
  plantPartChart: [],
  plantNoteChart: [],
  plantFamilyChart: [],
  chakraChart: [],
  stock_blends_found: false,
  fixedDR: null,
};

export default createReducer(initialState, {
  [UPDATE_PERCENT]: (state, { percent }) => ({
    percent,
  }),
  [UPDATE_PIECHART]: (state, { piechart }) => ({
    piechart,
  }),
  [UPDATE_EXPLODED_PIECHART]: (state, { explodedpiechart }) => ({
    explodedpiechart,
  }),
  [UPDATE_PLANTPARTCHART]: (state, { plantPartChart }) => ({
    plantPartChart,
  }),
  [UPDATE_PLANTNOTECHART]: (state, { plantNoteChart }) => ({
    plantNoteChart,
  }),
  [UPDATE_PLANTFAMILYCHART]: (state, { plantFamilyChart }) => ({
    plantFamilyChart,
  }),
  [UPDATE_CHAKRACHART]: (state, { chakraChart }) => ({
    chakraChart,
  }),
  [UPDATE_STOCK_BLENDS_FOUND]: (state, { stock_blends_found }) => ({
    stock_blends_found,
  }),
  [UPDATE_FIXED_DR]: (state, { fixedDR }) => ({
    fixedDR,
  }),
  [CLEAR]: (state, action) => RESET_STORE,
});
