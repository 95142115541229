import createReducer, { RESET_STORE } from "../createReducer";
import qs from "query-string";
import { uploadHelper } from "./uploadHelper";
import { getToken } from "./user";
import axios from "../config-axios";
import { MODAL_PAGE_SIZE, SHORT_MESSAGE_DELAY } from "../constants";
import { message } from "antd";
import { isEmpty, isNumber } from "lodash";
import { ingredientGrams } from "./dilution";
export const GET_IFRACATEGORY_SUCCESS = "Ifra.GET_IFRACATEGORY_SUCCESS";
export const GET_IFRACATEGORY_FAILURE = "Ifra.GET_IFRACATEGORY_FAILURE";
export const CHANGE_SELECTED_IFRA_CATEGORY = "Ifra.CHANGE_SELECTED_IFRA_CATEGORY";
export const CLEAR_SELECTED_IFRA_CATEGORY = "Ifra.CLEAR_SELECTED_IFRA_CATEGORY";
export const CLEAR = "Ifra.CLEAR";
export const SET_HYDROUS_MODE = "Ifra.SET_HYDROUS_MODE";
export const SET_HYDROUS_ID = "Ifra.SET_HYDROUS_ID";
export const SET_IFRA_RISK = "Ifra.SET_IFRA_RISK";

export const GET_IFRA_ITEMS_REQUEST = "Ifra.GET_IFRA_ITEMS_REQUEST";
export const GET_IFRA_ITEMS_SUCCESS = "Ifra.GET_IFRA_ITEMS_SUCCESS";
export const GET_IFRA_ITEMS_FAILURE = "Ifra.GET_IFRA_ITEMS_FAILURE";
export const CHECK_IF_IFRA_ITEM_EXISTS = "Ifra.CHECK_IF_IFRA_ITEM_EXISTS";
export const LOADING_IFRAITEMS = "Ifra.LOADING_IFRAITEMS";
export const CHANGE_IFRA_ITEM_LOAD = "Ifra.CHANGE_IFRA_ITEM_LOAD";
export const PUSH_IFRA_ITEMS = "Ifra.PUSH_IFRA_ITEMS";
export const CONSTITUENT_RISKS = "Ifra.CONSTITUENT_RISKS";
export const CLEAR_CONSTITUENT_RISKS = "Ifra.CLEAR_CONSTITUENT_RISKS";

export const setHydrousMode = (hydrous) => (dispatch, getState) => {
  dispatch({ type: SET_HYDROUS_MODE, hydrous });
};

export const setHydrousID = (raw_material_id) => (dispatch, getState) => {
  dispatch({ type: SET_HYDROUS_ID, raw_material_id });
};

export const clearHydrousID = () => (dispatch, getState) => {
  dispatch(setHydrousID(-1));
};
export const clearConsitutentRisks = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_CONSTITUENT_RISKS });
};

export const updateSelectedIFRACategory = (id) => (dispatch, getState) => {
  let selectedIFRACategory = id;
  if (typeof selectedIFRACategory === "number") {
    selectedIFRACategory = selectedIFRACategory.toString();
  }
  dispatch({ type: CHANGE_SELECTED_IFRA_CATEGORY, selectedIFRACategory });
};

export const clearSelectedIFRACategory = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_SELECTED_IFRA_CATEGORY });
};

export const clearIFRA = () => (dispatch, getState) => {
  const { selectedIFRACategory, ifracategories } = getState().ifra;

  if (ifracategories && ifracategories.length > 0) {
    dispatch(updateSelectedIFRACategory(ifracategories[0]["id"].toString()));
  }

  return { type: CLEAR };
};

export const getWaters =
  (raw_materials) =>
  async (dispatch, getState, { fetch }) => {
    const { apiUrl } = getState().global;
    const { token } = dispatch(getToken());

    try {
      const response = await axios.get(apiUrl + "/pands/getwaters/", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      });
      return response.data.details;
    } catch (error) {
      console.log(typeof error);
      console.log(error);
      message.error("Duplicate, already exists!", SHORT_MESSAGE_DELAY);
    }
  };

export const loadingIfraItems = () => (dispatch, getState) => {
  let { ifraItemsLoad } = getState().ifra;
  ifraItemsLoad = true;
  dispatch({ type: LOADING_IFRAITEMS, ifraItemsLoad });
};

export const getIfraItems =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    dispatch({ type: GET_IFRA_ITEMS_REQUEST, params });
    dispatch(loadingIfraItems());
    const { token } = dispatch(getToken());
    const page_size = params.page_size ? params.page_size : MODAL_PAGE_SIZE;
    let { search, ordering } = getState().ifra;
    const page = params.page ? params.page : undefined;
    const found = params.found ? params.found : undefined;
    search = params.name ? params.name : search;

    return fetch(
      `/pands/ifra_item/?${qs.stringify({
        search,
        ordering,
        page,
        page_size,
      })}`,
      {
        method: "GET",
        token,
        success: (res) => {
          const count = res.count;
          const { results, next } = res;
          if (params.ispush) dispatch({ type: PUSH_IFRA_ITEMS, results });
          dispatch({ type: GET_IFRA_ITEMS_SUCCESS, res, next: res.count });
          dispatch({ type: CHECK_IF_IFRA_ITEM_EXISTS, next });
          const value = false;
          dispatch({ type: CHANGE_IFRA_ITEM_LOAD, value });
        },
        // eslint-disable-next-line node/handle-callback-err
        failure: (err) => dispatch({ type: GET_IFRA_ITEMS_FAILURE }),
      }
    );
  };

export const getIFRACategories = () => (dispatch, getState) => {
  const { apiUrl } = getState().global;
  const { token } = dispatch(getToken());
  const url = "/pands/ifra_category/";
  return axios({
    method: "get",
    url: apiUrl + url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      const results = response.data.results;
      dispatch({ type: GET_IFRACATEGORY_SUCCESS, results });
      return response;
    })
    .catch((error) => {
      dispatch({ type: GET_IFRACATEGORY_FAILURE });
      message.error(`Getting Ifra Categories failed: ${error}`);
    });
};

// get the maximum value allowed by ifra for a specific ifra category
const getIFRAMax = (ifraitem, ifracategories, selectedIFRACategory) => {
  if (!(ifraitem && ifraitem.categories)) {
    return null;
  }
  const cats = ifraitem.categories;
  const catkeys = Object.keys(cats);
  if (!(cats && catkeys.length > 0)) return null;

  const ifracat = ifracategories.find((catobj) => catobj.id === parseInt(selectedIFRACategory));

  if (!ifracat || (ifracat && isNaN(cats[ifracat.category]))) return null;
  return parseFloat(cats[ifracat.category]);
};

const matchIFRAItemWithConsituent = (ifraitem) => {};

// find the consitituent, if any, (User added) which has an associated IFRA restriction
const getMatchingConstituent = (ifraitem, constituents) => {
  const casNumbers = ifraitem.cas;

  // Filter constituents based on matching CAS numbers
  const matchedConstituents = constituents.filter((constituent) => casNumbers.includes(constituent.cas.cas));
  if (matchedConstituents.length > 0) return matchedConstituents[0];

  return null;
};

/*
  percent_of_total represents the percentage of the total product
  e.g.:
  
*/
export const calculateIngredientRisk = (ing, selectedIFRACategory, ifracategories, constituentRisks, parentIng, percentOfTotal) => {
  const rm = ing.raw_material;
  const rmid = !isEmpty(parentIng) ? parentIng.raw_material.id : rm.id;
  const sb_adjustment = percentOfTotal ? percentOfTotal : ing.quantity;
  rm.ifraitems.map((ifraitem) => {
    // find the consitituent, if any, (User added) which has an associated IFRA restriction
    const constituent = getMatchingConstituent(ifraitem, rm.constituents);
    const is_stock_blend = parentIng ? true : false;

    // IFRA match found
    if (constituent) {
      // get the maximum value allowed by ifra for a specific ifra category
      let iframax = getIFRAMax(ifraitem, ifracategories, selectedIFRACategory);

      // An IFRA match may exist, but have no limitation
      if (!iframax) iframax = "";

      // Calculate the maximum quantity (W/W%) for this constituent
      const constituentQuantity = (constituent.quantity / 100) * sb_adjustment;

      // add constituent quantity and limit information to the
      // ifraitems object
      const myid = `${rmid}_${ifraitem.id}`;
      const exceeded = isNumber(iframax) ? constituentQuantity > iframax : false;

      const newObject = {
        rmid: rmid,
        iframax: iframax,
        cid: ifraitem.id,
        name: ifraitem.name,
        exceeded: exceeded,
        constituentQuantity: constituentQuantity,
        sum: constituentQuantity,
        sumExceeded: exceeded,
        parentrmid: parentIng ? parentIng.raw_material.id : null,
      };

      constituentRisks[myid] = { ...newObject };
    }
  });
};

export const addStockBlendConstituent = (parentIng, selectedIFRACategory, ifracategories, constituentRisks) => {
  const product = parentIng.raw_material.stock_blend_product;

  if (product) {
    product.recipe.ingredients.forEach((ingredient) => {});

    //calculate grams and total grams of the stock blend ingredients
    let total_grams = 0;
    let grams_hash = {};

    product.recipe.ingredients.forEach((stockBlendIngredient) => {
      /*
              calculate the amount of a stock blend ingredient as a
              ratio of the original stock blend times the users
              amount of the stock blend
              e.g. stock blend:
              almond oil 85% 
              lemongrass  5%
              peppermint  5%
              algae       5%
            */
      const percentOfTotal = (((stockBlendIngredient.quantity / 100) * parentIng.quantity) / 100) * 100;
      calculateIngredientRisk(stockBlendIngredient, selectedIFRACategory, ifracategories, constituentRisks, parentIng, percentOfTotal);
    });
  }
};

const calculateConstituentTotals = (constituentRisks) => {
  const totals = {};

  // compile totals
  Object.keys(constituentRisks).map((myid) => {
    const ingdata = constituentRisks[myid];
    const cid = ingdata.cid;
    if (cid in totals) {
      totals[cid].sum += ingdata.constituentQuantity;
      if (isNumber(ingdata.iframax) && totals[cid].sum >= ingdata.iframax) totals[cid].sumExceeded = true;
      // unique constituent found
    } else {
      totals[cid] = { sum: ingdata.constituentQuantity, sumExceeded: ingdata.sumExceeded };
    }
  });
  // update constituentRisks with the totals
  Object.keys(constituentRisks).map((myid) => {
    const ingdata = constituentRisks[myid];
    const cid = ingdata.cid;
    if (cid in totals) {
      ingdata.sum = totals[cid].sum;
      ingdata.sumExceeded = totals[cid].sumExceeded;
    }
  });

  return totals;
};

//
//   which constituents have the failing constituent
//   is sum of a single constituent too high.
export const calculateConstituentRisk = () => (dispatch, getState) => {
  const { selectedRawMaterials } = getState().rawMaterials;
  const { selectedIFRACategory, ifracategories } = getState().ifra;

  // store adjusted ifraitem objects in this variable
  const ifrarisksummary = {};

  // For each Ingredient (ing)
  const constituentRisks = {};
  selectedRawMaterials.map((ing) => {
    // let myConstitituents = {}

    if (ing.raw_material.stock_blend) {
      addStockBlendConstituent(ing, selectedIFRACategory, ifracategories, constituentRisks);
    } else {
      calculateIngredientRisk(ing, selectedIFRACategory, ifracategories, constituentRisks);
    }
    // if (!isEmpty(myConstitituents)) {
    //     constituentRisks[ing.raw_material.id] = myConstitituents;
    // }
    //    const ifraitems = {};
  });
  const totals = calculateConstituentTotals(constituentRisks);

  dispatch({ type: CONSTITUENT_RISKS, constituentRisks });
  return constituentRisks;
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  ifraitems: [],
  search: null,
  ordering: null,
  hasNextPage: true,
  ifraItemsLoad: false,
  ifracategories: [],
  selectedIFRACategory: "",
  hydrous: true,
  hydrousID: -1,
  ifrarisksummary: undefined,
  constituentRisks: {},
};

export default createReducer(initialState, {
  [GET_IFRACATEGORY_SUCCESS]: (state, { results }) => ({
    ifracategories: results,
  }),
  [GET_IFRACATEGORY_FAILURE]: (state, {}) => ({}),
  [CHANGE_SELECTED_IFRA_CATEGORY]: (state, { selectedIFRACategory }) => ({
    selectedIFRACategory,
  }),
  [SET_IFRA_RISK]: (state, { ifrarisksummary }) => ({
    ifrarisksummary,
  }),
  [SET_HYDROUS_MODE]: (state, { hydrous }) => ({
    hydrous: hydrous,
  }),
  [SET_HYDROUS_ID]: (state, { raw_material_id }) => ({
    hydrousID: raw_material_id,
  }),
  [CLEAR_SELECTED_IFRA_CATEGORY]: (state) => ({
    selectedIFRACategory: [],
  }),
  [CLEAR_CONSTITUENT_RISKS]: (state) => ({
    constituentRisks: {},
  }),
  [CLEAR]: (state) => ({
    hydrous: true,
    hydrousID: -1,
    constituentRisks: {},
  }),
  [GET_IFRA_ITEMS_SUCCESS]: (state, { res: { results, next }, count }) => ({
    ifraitems: results,
    loading: {
      ...state.loading,
      ifraitems: false,
    },
    ifraItemsLoad: false,
    count: count,
  }),
  [CHECK_IF_IFRA_ITEM_EXISTS]: (state, { next }) => ({
    hasNextPage: next,
  }),
  [LOADING_IFRAITEMS]: (state, { ifraItemsLoad }) => ({
    ifraItemsLoad,
  }),
  [CHANGE_IFRA_ITEM_LOAD]: (state, { value }) => ({
    initialProductLoad: value,
    productsLoad: value,
  }),
  [PUSH_IFRA_ITEMS]: (state, action) => ({
    ifraitems: state.ifraitems.concat(action.results),
    loading: false,
  }),
  [GET_IFRA_ITEMS_REQUEST]: (state, { params }) => ({
    search: _.has(params, "search") ? params.search : state.search,
    ordering: params.sorter ? `${params.sorter.order === "descend" ? "-" : ""}${params.sorter.columnKey}` : state.ordering,

    loading: {
      ...state.loading,
      ifraitems: true,
    },
  }),
  [CONSTITUENT_RISKS]: (state, { constituentRisks }) => ({
    constituentRisks,
  }),
});
